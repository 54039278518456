import React, { useState, useEffect, createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Search,
  ChevronRight,
  X,
  Calendar,
  CheckCircle,
  Clock,
  PlusCircle,
  Edit,
  Trash2,
  Sun,
  Moon,
} from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import {
  useGetTasksQuery,
  useCreateTaskMutation,
  useUpdateTaskMutation,
  useDeleteTaskMutation,
} from "../../../../generated/graphql";
const ThemeContext = createContext({
  isDarkMode: false,
  toggleTheme: () => {},
});

interface Task {
  id: string;
  task_name: string;
  task_desc: string;
  deadline: string; // GraphQL `DateTimeISO` is usually represented as a string
  points: number;
}

const TaskRibbon: React.FC<{
  task: Task;
  onClick: (task: Task) => void;
  onEdit: (task: Task) => void;
  onDelete: (taskId: string) => void;
}> = ({ task, onClick, onEdit, onDelete }) => {
  const { isDarkMode } = useContext(ThemeContext);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.3 }}
      className={`flex items-center p-6 mb-4 rounded-xl shadow-lg cursor-pointer transition-all duration-300 transform hover:-translate-y-1 hover:shadow-xl ${
        isDarkMode
          ? "bg-gray-800 hover:bg-gray-700 text-white"
          : "bg-white hover:bg-gray-50 text-gray-800"
      }`}
      onClick={() => onClick(task)}
    >
      {/* <div
        className={`w-12 h-12 rounded-full mr-6 flex items-center justify-center ${
          task.status === "completed" ? "bg-green-500" : "bg-yellow-500"
        }`}
      >
        {task.status === "completed" ? (
          <CheckCircle size={24} className="text-white" />
        ) : (
          <Clock size={24} className="text-white" />
        )}
      </div> */}
      <div className="flex-grow">
        <h3 className="text-xl font-semibold mb-1">{task.task_name}</h3>
        <p
          className={`text-sm ${
            isDarkMode ? "text-gray-300" : "text-gray-600"
          }`}
        >
          Due: {new Date(task.deadline).toLocaleDateString()}
        </p>
      </div>
      <div className="flex items-center">
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          className={`mr-2 p-2 rounded-full ${
            isDarkMode
              ? "text-gray-300 hover:bg-gray-700"
              : "text-gray-600 hover:bg-gray-200"
          }`}
          onClick={(e) => {
            e.stopPropagation();
            onEdit(task);
          }}
        >
          <Edit size={20} />
        </motion.button>
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          className={`mr-4 p-2 rounded-full ${
            isDarkMode
              ? "text-red-400 hover:bg-red-900"
              : "text-red-500 hover:bg-red-100"
          }`}
          onClick={(e) => {
            e.stopPropagation();
            onDelete(task.id);
          }}
        >
          <Trash2 size={20} />
        </motion.button>
        <ChevronRight
          className={`${isDarkMode ? "text-gray-400" : "text-gray-400"}`}
          size={24}
        />
      </div>
    </motion.div>
  );
};

const TaskPopup: React.FC<{
  task: Task;
  onClose: () => void;
  onEdit: (task: Task) => void;
}> = ({ task, onClose, onEdit }) => {
  const { isDarkMode } = useContext(ThemeContext);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
    >
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        transition={{ type: "spring", damping: 25, stiffness: 500 }}
        className={`${
          isDarkMode ? "bg-gray-800" : "bg-white"
        } rounded-2xl max-w-2xl w-full max-h-[90vh] overflow-y-auto relative p-8`}
      >
        <button
          onClick={onClose}
          className={`absolute top-6 right-6 ${
            isDarkMode
              ? "text-gray-300 hover:text-gray-100"
              : "text-gray-500 hover:text-gray-700"
          } transition-colors duration-200`}
        >
          <X size={28} />
        </button>
        <h2
          className={`text-3xl font-bold mb-4 ${
            isDarkMode ? "text-white" : "text-gray-800"
          }`}
        >
          {task.task_name}
        </h2>
        <p
          className={`text-lg mb-4 ${
            isDarkMode ? "text-gray-300" : "text-gray-600"
          }`}
        >
          {task.task_desc}
        </p>

        <div
          className={`flex items-center mb-4 ${
            isDarkMode ? "text-gray-300" : "text-gray-600"
          }`}
        >
          <Calendar size={20} className="mr-2" />
          <span>Due: {new Date(task.deadline).toLocaleDateString()}</span>
        </div>
        {/* <div
          className={`flex items-center ${
            isDarkMode ? "text-gray-300" : "text-gray-600"
          }`}
        >
          {task.status === "completed" ? (
            <CheckCircle size={20} className="mr-2 text-green-500" />
          ) : (
            <Clock size={20} className="mr-2 text-yellow-500" />
          )}
          <span>Status: {task.status}</span>
        </div> */}
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => onEdit(task)}
          className={`mt-6 px-4 py-2 rounded-lg ${
            isDarkMode
              ? "bg-blue-600 hover:bg-blue-700 text-white"
              : "bg-blue-500 hover:bg-blue-600 text-white"
          } transition-colors duration-200`}
        >
          Edit Task
        </motion.button>
      </motion.div>
    </motion.div>
  );
};

const TaskForm: React.FC<{
  task: Task | null;
  onSubmit: (task: Omit<Task, "id">) => void;
  onClose: () => void;
}> = ({ task, onSubmit, onClose }) => {
  const { isDarkMode } = useContext(ThemeContext);
  const [task_name, setTask_name] = useState(task?.task_name || "");
  const [task_desc, setTask_desc] = useState(task?.task_desc || "");
  const [deadline, setDeadline] = useState(task?.deadline || "");
  const [points, setPoints] = useState(task?.points || 0);
  // const [status, setStatus] = useState<"pending" | "completed">(
  //   task?.status || "pending"
  // );

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    //const submit_deadline = deadline.toISOString();
    onSubmit({ task_name, task_desc, deadline, points });
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
    >
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        transition={{ type: "spring", damping: 25, stiffness: 500 }}
        className={`${
          isDarkMode ? "bg-gray-800" : "bg-white"
        } rounded-2xl max-w-2xl w-full max-h-[90vh] overflow-y-auto relative p-8`}
      >
        <button
          onClick={onClose}
          className={`absolute top-6 right-6 ${
            isDarkMode
              ? "text-gray-300 hover:text-gray-100"
              : "text-gray-500 hover:text-gray-700"
          } transition-colors duration-200`}
        >
          <X size={28} />
        </button>
        <h2
          className={`text-3xl font-bold mb-6 ${
            isDarkMode ? "text-white" : "text-gray-800"
          }`}
        >
          {task ? "Edit Task" : "Add New Task"}
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="title"
              className={`block mb-2 ${
                isDarkMode ? "text-gray-300" : "text-gray-700"
              }`}
            >
              Title
            </label>
            <input
              type="text"
              id="title"
              value={task_name}
              onChange={(e) => setTask_name(e.target.value)}
              className={`w-full p-2 rounded-lg ${
                isDarkMode
                  ? "bg-gray-700 text-white"
                  : "bg-gray-100 text-gray-800"
              } focus:outline-none focus:ring-2 focus:ring-blue-500`}
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="description"
              className={`block mb-2 ${
                isDarkMode ? "text-gray-300" : "text-gray-700"
              }`}
            >
              Description
            </label>
            <textarea
              id="description"
              value={task_desc}
              onChange={(e) => setTask_desc(e.target.value)}
              className={`w-full p-2 rounded-lg ${
                isDarkMode
                  ? "bg-gray-700 text-white"
                  : "bg-gray-100 text-gray-800"
              } focus:outline-none focus:ring-2 focus:ring-blue-500`}
              rows={4}
              required
            ></textarea>
          </div>
          <div className="mb-4">
            <label
              htmlFor="dueDate"
              className={`block mb-2 ${
                isDarkMode ? "text-gray-300" : "text-gray-700"
              }`}
            >
              Due Date
            </label>
            <input
              type="date"
              id="dueDate"
              value={deadline}
              onChange={(e) => setDeadline(e.target.value)}
              className={`w-full p-2 rounded-lg ${
                isDarkMode
                  ? "bg-gray-700 text-white"
                  : "bg-gray-100 text-gray-800"
              } focus:outline-none focus:ring-2 focus:ring-blue-500`}
              required
            />
          </div>
          {/* <div className="mb-6">
            <label
              className={`block mb-2 ${
                isDarkMode ? "text-gray-300" : "text-gray-700"
              }`}
            >
              Status
            </label>
            <div className="flex items-center">
              <input
                type="radio"
                id="pending"
                value="pending"
                checked={status === "pending"}
                onChange={() => setStatus("pending")}
                className="mr-2"
              />
              <label
                htmlFor="pending"
                className={`mr-4 ${
                  isDarkMode ? "text-gray-300" : "text-gray-700"
                }`}
              >
                Pending
              </label>
              <input
                type="radio"
                id="completed"
                value="completed"
                checked={status === "completed"}
                onChange={() => setStatus("completed")}
                className="mr-2"
              />
              <label
                htmlFor="completed"
                className={`${isDarkMode ? "text-gray-300" : "text-gray-700"}`}
              >
                Completed
              </label>
            </div>
          </div> */}
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            type="submit"
            className={`w-full px-4 py-2 rounded-lg ${
              isDarkMode
                ? "bg-blue-600 hover:bg-blue-700 text-white"
                : "bg-blue-500 hover:bg-blue-600 text-white"
            } transition-colors duration-200`}
          >
            {task ? "Update Task" : "Add Task"}
          </motion.button>
        </form>
      </motion.div>
    </motion.div>
  );
};

const TaskManagement: React.FC = () => {
  const [tasks, setTasks] = useState<Task[]>([]);
  const [filteredTasks, setFilteredTasks] = useState<Task[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedTask, setSelectedTask] = useState<Task | null>(null);
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
  const [editingTask, setEditingTask] = useState<Task | null>(null);
  const [isDarkMode, setIsDarkMode] = useState<boolean>(true);
  const navigate = useNavigate();

  const { data: taskData, refetch } = useGetTasksQuery();
  const [createTask] = useCreateTaskMutation();
  const [updateTask] = useUpdateTaskMutation();
  const [deleteTask] = useDeleteTaskMutation();
  const generateCSV = (tasks: Task[]) => {
    const csvRows = [
      ["Task Name", "Description", "Deadline"],
      ...tasks.map((task) => [
        task.task_name || "",
        task.task_desc || "",
        task.deadline || "",
      ]),
    ];

    return csvRows.map((row) => row.join(",")).join("\n");
  };
  const handleDownloadCSV = () => {
    const csv = generateCSV(tasks);
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "tasks.csv";
    a.click();
    window.URL.revokeObjectURL(url);
  };
  useEffect(() => {
    if (taskData?.getTasks) {
      setTasks(taskData.getTasks as unknown as Task[]);
    }
  }, [taskData]);

  useEffect(() => {
    const filtered = tasks.filter((task) =>
      task.task_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredTasks(filtered);
    console.log(filteredTasks);
  }, [searchTerm, tasks]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };
  const handleAddTask = async (newTask: Omit<Task, "id">) => {
    try {
      const currentTime = new Date();

      const { data } = await createTask({
        variables: {
          taskName: newTask.task_name,
          taskDesc: newTask.task_desc,
          deadline: newTask.deadline,
          created_at: currentTime.toDateString(),
          points: newTask.points,
        },
      });
      if (data?.createTask) {
        // setTasks([...tasks, data.createTask as unknown as Task]);
        setIsFormOpen(false);
      }
      refetch();
    } catch (error) {
      console.error("Error creating task:", error);
    }
  };

  const handleUpdateTask = async (updatedTask: Omit<Task, "id">) => {
    if (!editingTask) return;

    try {
      const { data } = await updateTask({
        variables: {
          id: editingTask.id,
          task_desc: updatedTask.task_desc,
          taskName: updatedTask.task_name,
          deadline: updatedTask.deadline,
        },
      });
      if (data?.updateTask) {
        setTasks(
          tasks.map((task) =>
            task.id === editingTask.id
              ? {
                  ...task,
                  ...updatedTask,
                  task_desc: data.updateTask.task_desc,
                }
              : task
          )
        );
        setEditingTask(null);
      }
      refetch();
    } catch (error) {
      console.error("Error updating task:", error);
    }
  };

  const handleDeleteTask = async (taskId: string) => {
    try {
      const { data } = await deleteTask({
        variables: { id: taskId },
      });
      if (data?.deleteTask) {
        setTasks(tasks.filter((task) => task.id !== taskId));
      }
    } catch (error) {
      console.error("Error deleting task:", error);
    }
  };

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleTheme }}>
      <div
        className={`min-h-screen ${
          isDarkMode ? "bg-gray-900 text-white" : "bg-gray-100 text-gray-800"
        } pt-24 mt-20`}
      >
        <motion.div
          initial={{ y: -100 }}
          animate={{ y: 0 }}
          className={`fixed top-0 left-0 right-0 ${
            isDarkMode ? "bg-gray-800" : "bg-white"
          } shadow-lg z-10`}
        >
          <div className="container mx-auto px-6 py-4">
            <div className="flex justify-between items-center">
              <h1 className="text-4xl font-extrabold text-gray-900 dark:text-gray-100 bg-gradient-to-r from-blue-500 to-teal-500 text-transparent bg-clip-text p-2">
                Task Management
              </h1>
              <div className="flex items-center space-x-4">
                <motion.button
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  onClick={toggleTheme}
                  className={`p-3 rounded-full ${
                    isDarkMode
                      ? "bg-gray-700 text-yellow-300"
                      : "bg-gray-200 text-gray-800"
                  } transition-colors duration-200`}
                >
                  {isDarkMode ? <Sun size={24} /> : <Moon size={24} />}
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => setIsFormOpen(true)}
                  className={`px-4 py-2 rounded-lg ${
                    isDarkMode
                      ? "bg-blue-600 hover:bg-blue-700 text-white"
                      : "bg-blue-500 hover:bg-blue-600 text-white"
                  } transition-colors duration-200 flex items-center`}
                >
                  <PlusCircle size={20} className="mr-2" />
                  Add Task
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={handleDownloadCSV}
                  className={`px-4 py-2 rounded-lg ${
                    isDarkMode
                      ? "bg-gray-600 hover:bg-gray-700 text-white"
                      : "bg-gray-500 hover:bg-gray-600 text-white"
                  } transition-colors duration-200`}
                >
                  CSV
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => navigate("/admin")}
                  className={`px-4 py-2 rounded-lg ${
                    isDarkMode
                      ? "bg-gray-600 hover:bg-gray-700 text-white"
                      : "bg-gray-500 hover:bg-gray-600 text-white"
                  } transition-colors duration-200`}
                >
                  Back to Admin
                </motion.button>
              </div>
            </div>
            <div className="mt-6 relative">
              <input
                type="text"
                placeholder="Search tasks..."
                value={searchTerm}
                onChange={handleSearch}
                className={`w-full p-3 pl-12 rounded-lg border text-lg ${
                  isDarkMode
                    ? "bg-gray-700 text-white border-gray-600"
                    : "bg-white text-gray-800 border-gray-300"
                } focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-200`}
              />
              <Search
                size={24}
                className={`absolute left-4 top-1/2 transform -translate-y-1/2 ${
                  isDarkMode ? "text-gray-400" : "text-gray-500"
                }`}
              />
            </div>
          </div>
        </motion.div>

        <div className="container mx-auto px-6 pt-8">
          <AnimatePresence>
            {filteredTasks.map((task) => (
              <TaskRibbon
                key={task.id}
                task={task}
                onClick={(selected) => setSelectedTask(selected)}
                onEdit={(task) => {
                  setEditingTask(task);
                  setIsFormOpen(true);
                }}
                onDelete={() => handleDeleteTask(task.id)}
              />
            ))}
          </AnimatePresence>
        </div>

        <AnimatePresence>
          {selectedTask && (
            <TaskPopup
              task={selectedTask}
              onClose={() => setSelectedTask(null)}
              onEdit={(task) => {
                setEditingTask(task);
                setIsFormOpen(true);
                setSelectedTask(null);
              }}
            />
          )}
        </AnimatePresence>

        <AnimatePresence>
          {isFormOpen && (
            <TaskForm
              task={editingTask}
              onSubmit={(task) => {
                if (editingTask) {
                  handleUpdateTask(task);
                } else {
                  handleAddTask(task);
                }
                setIsFormOpen(false);
                setEditingTask(null);
              }}
              onClose={() => {
                setIsFormOpen(false);
                setEditingTask(null);
              }}
            />
          )}
        </AnimatePresence>
      </div>
    </ThemeContext.Provider>
  );
};

export default TaskManagement;
