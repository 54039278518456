import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useGetUnsubmittedTasksQuery} from "../../../generated/graphql";
import TaskCard from "./TaskCard";
import { Loader, AlertCircle, Search, RefreshCw } from "lucide-react";
import { useGetMeQuery } from "../../../generated/graphql";
interface TasksProps {
  userId: string;
  username: string;
}

const Tasks: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const user = useGetMeQuery().data?.getMe;
  const { data, loading, error, refetch } = useGetUnsubmittedTasksQuery();

  const tasks = data?.getUnsubmittedTasks || [];
  const filteredTasks = tasks.filter(
    (task) =>
      task.task_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      task.task_desc.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const taskVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100,
      },
    },
  };

  return (
    <div className="p-6 bg-gradient-to-b from-indigo-900 via-indigo-800 to-purple-900  min-h-[80vh] rounded-lg m-10">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl font-bold text-indigo-100 mb-6">
          Submit Tasks
        </h1>
        <div className="mb-6 flex items-center space-x-4">
          <div className="relative flex-grow">
            <input
              type="text"
              placeholder="Search tasks..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full px-4 py-2 pl-10 pr-4 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
            <Search
              className="absolute left-3 top-2.5 text-gray-400"
              size={20}
            />
          </div>
          <button
            onClick={() => refetch()}
            className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors duration-200 flex items-center"
          >
            <RefreshCw size={20} className="mr-2" />
            Refresh
          </button>
        </div>
        {loading && (
          <div className="flex justify-center items-center h-64">
            <Loader className="animate-spin text-indigo-600" size={48} />
          </div>
        )}
        {error && (
          <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded-lg mb-6">
            <div className="flex items-center">
              <AlertCircle className="mr-2" size={24} />
              <p>Error loading tasks: {error.message}</p>
            </div>
          </div>
        )}
        {!loading && !error && filteredTasks.length === 0 && (
          <div className="text-center text-gray-500 py-12">
            <p className="text-2xl font-semibold mb-2">No tasks available</p>
            <p>
              Check back later for new tasks or try a different search term.
            </p>
          </div>
        )}
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <AnimatePresence>
            {filteredTasks.map((task) => (
              <motion.div
                key={task.id}
                variants={taskVariants}
                initial="hidden"
                animate="visible"
                exit="hidden"
                layout
                className="m-2"
              >
                <TaskCard task={task} username={user?.username || "Unknown"} />
              </motion.div>
            ))}
          </AnimatePresence>
        </motion.div>
      </div>
    </div>
  );
};

export default Tasks;
