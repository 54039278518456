import React, { useState, useCallback } from "react";
import {
  useGetMeQuery,
  useGetMilanEventByCityQuery,
  useCreateMilanRegistrationMutation,
} from "../../../generated/graphql";
import { useNavigate, useParams } from "react-router-dom";
import ButtonSvg from "../../../combonents/milan/buttonsvg"; 
import {
  Box,
  Typography,
  Modal,
  Button,
  List,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import Header from "../../../combonents/sa/header/header";
import Loader from "../../../combonents/Loader/loader";
import Footer from "../../../combonents/sa/footer/Footer";
import { ArrowRightCircleIcon } from "lucide-react";
interface MilanEvent {
  id: string;
  name: string;
  price: number;
  maxParticipants: number;
  description: string[];
  venue: string;
  date: string;
}

const white=false
const Events = () => {
  const navigate = useNavigate()

  const { city = "chennai" } = useParams<{ city?: string }>();
  const { data, loading, error } = useGetMilanEventByCityQuery({
    variables: { city },
  });
  const { data: userData } = useGetMeQuery();

  const [createMilanRegistration] = useCreateMilanRegistrationMutation();

  const [open, setOpen] = useState(false);
  const [registerOpen, setRegisterOpen] = useState(false);
  const [selectedDescription, setSelectedDescription] = useState<
    string[] | null
  >(null);
  const [participants, setParticipants] = useState(1);
  const [link,setLink] = useState("")
  const [selectedEvent, setSelectedEvent] = useState<{
    id: string;
    price: number;
    maxParticipants: number;
    city:string;
  } | null>(null);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [paymentError, setPaymentError] = useState<string | null>(null);
  const [paymentSuccess, setPaymentSuccess] = useState<string>("");

  // Modal handlers
  const handleOpen = (description: string[]) => {
    setSelectedDescription(description);
    setOpen(true);
  };

  const handleOpenRegister = (
    eventId: string,
    price: number,
    maxpart: number,
    city:string,

  ) => {
    setSelectedEvent({ id: eventId, price, maxParticipants: maxpart,city:city });
    setRegisterOpen(true);
    setErrorMsg(null);
    setPaymentError(null);
    setPaymentSuccess("");
  };

  const handleCloseRegister = () => {
    setRegisterOpen(false);
    setSelectedEvent(null);
    setParticipants(1);
    setPaymentError(null);
    setPaymentSuccess("");
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedDescription(null);
  };

  // Participants handler
  const handleParticipantsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = parseInt(event.target.value, 10);
    if (isNaN(value) || value < 0) {
      setParticipants(1);
      return;
    }
    setParticipants(value);
    if (selectedEvent && value > selectedEvent.maxParticipants) {
      setErrorMsg(
        `Maximum ${selectedEvent.maxParticipants} participants allowed`
      );
    } else {
      setErrorMsg(null);
    }
  };

  // Amount calculations
  const calculateBaseAmount = () => {
    return selectedEvent ?  selectedEvent.price : 0;
  };
  
  const calculateRazorpayFee = () => {
    return parseFloat((calculateBaseAmount() * 0.02).toFixed(2));
  };
  
  const calculateTotal = () => {
    return parseFloat((calculateBaseAmount() + calculateRazorpayFee()).toFixed(2));
  };

  // Payment handlers
  const handlePayment = useCallback(async () => {
    if(selectedEvent?.city==="online" &&(link===null || link==="" || link===undefined))
{
setErrorMsg("Please add drive link!!")
return
}    if (!selectedEvent) return;
    if (!userData?.getMe.user_id) {
      throw new Error("user id  ???");
    }
    try {
      const { data: registrationData } = await createMilanRegistration({
        variables: {
          eventId: selectedEvent.id,
          userId: userData?.getMe.user_id || "",
          link:link,
          // participants: participants,
        },
      });

      if (!registrationData?.createMilanRegistration) {
        throw new Error("Failed to create registration");
      }

      const totalAmount =calculateTotal()
      const totalAmountInPaise = Math.round(totalAmount * 100); 

      const razorpayKeyId =
        process.env.REACT_APP_RAZORPAY_KEY_ID || "rzp_live_VgVO9uzPsxViN5";
      if (!razorpayKeyId) {
        // throw new Error("Razorpay Key ID is not set in environment variables");
      }

      const options: RazorpayOptions = {
        key: razorpayKeyId,
        amount: totalAmountInPaise,
        currency: "INR",
        name: "Saarang Milans",
        description: "Payment Transactions",
        order_id: registrationData.createMilanRegistration.razorpay_order_id,
        handler: function (response) {
          handlePaymentSuccess(response);
        },
        notes: {
          type: "Saarang Milans 2025",
        },
        theme: {
          color: "#6f77fd",
        },
        prefill: {
          name: userData?.getMe.username,
          email: userData?.getMe.email_primary,
          contact: userData?.getMe.mobile_number_primary ?? "",
        },
      };

      if (typeof window.Razorpay === "undefined") {
        throw new Error("Razorpay script not loaded");
      }

      const razorpay = new window.Razorpay(options);
      razorpay.open();
    } catch (error) {
      if (error instanceof Error) {
        setPaymentError(`Payment initiation failed: ${error.message}`);
      } else {
        setPaymentError("Payment initiation failed. Please try again.");
      }
      console.error("Payment error:", error);
    }
  }, [selectedEvent, participants, createMilanRegistration, calculateTotal]);

  const handlePaymentSuccess = async (response: {
    razorpay_payment_id: string;
    razorpay_order_id: string;
    razorpay_signature: string;
  }) => {
    try {
      setPaymentSuccess("Payment successful! Your registration is confirmed.");
      handleCloseRegister();
    } catch (error) {
      console.error("Payment verification failed:", error);
      setPaymentError("Payment verification failed. Please contact support.");
    }
  };

  // Loading and error states
  if (loading)
    return (
      <div className="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] scale-[5]">
        {" "}
        <Loader />
      </div>
    );
  if (error) return <div>Error loading events</div>;

 
  const colors = ["#FFC876", "#AC6AFF", "#FF7770"]; 
  
  const eventDate = data?.getMilanEventByCity?.[0]?.date;
  const eventVenue = data?.getMilanEventByCity?.[0]?.venue;
  const formattedDate = eventDate
    ? new Date(eventDate).toLocaleDateString("en-GB", {
        day: "numeric",
        month: "long",
        year: "numeric",
      })
    : "";
  return (
    <div className="bg-[#0E0C15] min-h-[100vh] flex flex-col items-stretch space-y-24">
      <Header />
      {/* Header */}
      <div className="lg:px-64 rounded-md  flex justify-center mx-4 items-center my-4 py-4 flex-col">
        <div className="font-semibold text-[2.5rem] leading-[3.25rem] md:text-[2.75rem] md:leading-[3.75rem] lg:text-[3.25rem] lg:leading-[4.0625rem] xl:text-[3.75rem] xl:leading-[4.5rem]">
          {city==="online"?`Online Milans`:city.charAt(0).toUpperCase() + city.slice(1)}
        </div>
        {city==="online"? <div className=" text-[1rem] leading-normal md:text-[2rem]">
          12-30 November 2024 
        </div>:
        <div className=" text-[1rem] leading-normal md:text-[2rem]">
          {formattedDate}, {eventVenue}
        </div>}
    
      </div>
    
      {/* Event Cards */}
      <div className="flex flex-wrap w-full gap-12 justify-center">
        {data?.getMilanEventByCity?.length ? (
          data?.getMilanEventByCity?.map((milanEvent, index) => (
            <div
              key={milanEvent.id}
              className="w-96  h-full px-6 bg-[#0E0C15] border-[#252134] border-[2px] rounded-[2rem] py-8 flex flex-col"
            >
              <div className="mb-4 text-[2rem] leading-normal text-[#FFC876] font-sans2"
               style={{ color: colors[index % colors.length] }}>
                {milanEvent.name}
              </div>

          <div className="flex items-center h-[5.5rem] mb-6">
            {milanEvent.price && (
              <>
                <div className="text-[1rem] leading-normal md:text-[1.5rem]">₹</div>
                <div className="text-[3.5rem] leading-none font-bold">
                {milanEvent.price}
                </div>
              </>
            )}
          </div>

              <Typography className="text-neutral-300 px-2 space-y-1 flex-shrink-0 text-start pb-3">
                <h1 className="font-semibold text-lg leading-8">
                  Max Participants: {milanEvent.maxParticipants}
                </h1>
              </Typography>

              
                <button
                  className="button relative inline-flex items-center justify-center h-11 transition-colors hover:text-[#AC6AFF] px-7 text-[#0E0C15]"
                  onClick={() => handleOpen(milanEvent.description)}
                >
                  <span className="relative z-10">Description</span>
                  <ButtonSvg white={true} />
                </button>
                {userData?.getMe?.user_id ? (
                  <button
                    className="button relative inline-flex items-center justify-center my-3 h-11 transition-colors hover:text-[#AC6AFF] px-7 text-white"
                    onClick={() =>
                      handleOpenRegister(
                        milanEvent.id,
                        milanEvent.price,
                        milanEvent.maxParticipants,
                        milanEvent.city
                      )
                    }
                  >
                    <span className="relative z-10">Register</span>
                    <ButtonSvg white={false} />
                  </button>
                ) : (
                  <button
                     className="button relative inline-flex items-center justify-center h-11 transition-colors hover:text-[#AC6AFF] px-7 my-3 text-white "
                    onClick={() => {
                      alert("Please login to register.");
                      window.location.href = "/auth";
                    }}
                  >
                    <span className="relative z-10">Register</span>
                    <ButtonSvg white={false} />
                  </button>
                )}
             
            </div>
          ))
        ) : (
          <div className="flex flex-col items-center justify-center w-full h-full py-20">
            <Typography variant="h2" className="text-gray-400">
              Coming Soon...
            </Typography>
          </div>
        )}
      </div>
      <Footer />

      {/* Description Modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="event-description-title"
        aria-describedby="event-description"
      >
        <Box
         sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "90%", sm: "70%", md: "50%", lg: "40%" },
          bgcolor: "#0E0C15",
          border: "2px solid #000",
          boxShadow: "0 0 15px 5px rgba(255, 255, 255, 0.8)",
          p: 4,
          maxHeight: "90vh",
          overflowY: "auto",
          borderRadius: "16px", // Adjust for rounded edges
          // Hide scrollbar across all browsers
          "&::-webkit-scrollbar": { display: "none" }, // For Chrome, Safari, and Opera
          "-ms-overflow-style": "none", // For IE and Edge
          "scrollbar-width": "none", // For Firefox
        }}
        >
          <Typography
            id="event-description-title"
            variant="h6"
            component="h2"
            className="text-white"
          >
            Event Description
          </Typography>
          {selectedDescription && (
            <List id="event-description" sx={{ mt: 2 }}>
           {selectedDescription.map((point, index) => (
                <ListItem key={index} className='text-slate-300'>
                    <ListItemText
                        primary={
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: `• ${point}`
                                }}
                            />
                        }
                    />
                </ListItem>
            ))}
            </List>
          )}
          <div className="flex flex-row justify-between w-full">
            <Button
              onClick={handleClose}
              variant="outlined"
              sx={{
                mt: 3,
                backgroundColor: "rgb(111, 119, 253)",
                color: "white",
                "&:hover": {
                  backgroundColor: "rgba(111, 119, 253, 0.8)",
                },
              }}
            >
              Close
            </Button>
          </div>
        </Box>
      </Modal>

      {/* Register Modal */}
      <Modal
        open={registerOpen}
        onClose={handleCloseRegister}
        aria-labelledby="event-register-title"
        aria-describedby="event-register"
        
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", sm: "70%", md: "50%", lg: "40%" },
            bgcolor: "#0E0C15",
            border: "2px solid #000",
            boxShadow: "0 0 15px 5px rgba(255, 255, 255, 0.8)",
            p: 4,
            maxHeight: "90vh",
            overflowY: "auto",
            borderRadius: "12px",
          }}
        >
          <Typography
            id="event-register-title"
            variant="h6"
            component="h2"
            className="text-white"
          >
            Register for event
          </Typography>
          {selectedEvent?.city==="online" &&

          <TextField
            label="Add your drive link here. Give access to all."
            type="text"
            value={link}
            onChange={(e)=>{setLink(e.target.value);setErrorMsg("")}}
            fullWidth
            sx={{
              mt: 3,
              "& label": {
                color: "white",
              },
              "& label.Mui-focused": {
                color: "white",
              },
              "& .MuiInputBase-input": {
                color: "white",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "white",
                },
                "&:hover fieldset": {
                  borderColor: "white",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "white",
                },
              },
            }}
         
          />}
          {errorMsg && (
            <Typography className="text-red-500 mt-2">{errorMsg}</Typography>
          )}
          {paymentError && (
            <Typography className="text-red-500 mt-2">
              {paymentError}
            </Typography>
          )}
          {paymentSuccess && (
            <Typography className="text-green-500 mt-2">
              {paymentSuccess}
            </Typography>
          )}
          <Typography className="text-white py-2 lg:px-16 px-6">
            Amount Breakdown:
          </Typography>
          <div className="w-full flex flex-row justify-between lg:px-16 px-6">
            <div>
              <Typography className="text-slate-300 mt-1">
                Base Amount:
              </Typography>
              <Typography className="text-slate-300 mt-1">
                Razorpay Fee (2%):
              </Typography>
              <Typography className="text-white mt-1 font-bold pt-1">
                Total Amount:
              </Typography>
            </div>
            <div>
              <Typography className="text-slate-300 mt-1">
                {calculateBaseAmount()} Rs
              </Typography>
              <Typography className="text-slate-300 mt-1">
                {calculateRazorpayFee().toFixed(2)} Rs
              </Typography>
              <Typography className="text-white pt-1 font-bold">
                {calculateTotal().toFixed(2)} Rs
              </Typography>
            </div>
          </div>
          <div className="flex flex-row justify-between w-full mt-8">
            <Button
              onClick={handleCloseRegister}
              variant="outlined"
              sx={{
                backgroundColor: "rgb(111, 119, 253)",
                color: "white",
                "&:hover": {
                  backgroundColor: "rgba(111, 119, 253, 0.8)",
                },
              }}
            >
              Close
            </Button>
            <Button
              onClick={handlePayment}
              variant="outlined"
              disabled={!!errorMsg}
              sx={{
                backgroundColor: "rgb(111, 119, 253)",
                color: "white",
                "&:hover": {
                  backgroundColor: "rgba(111, 119, 253, 0.8)",
                },
                "&.Mui-disabled": {
                  backgroundColor: "rgba(111, 119, 253, 0.5)",
                  color: "rgba(255, 255, 255, 0.5)",
                },
              }}
            >
              Register
            </Button>
          </div>
        </Box>
      </Modal>
      
    </div>
  );
};

export default Events;
