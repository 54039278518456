import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import {
  Home,
  CheckSquare,
  ListTodo,
  Trophy,
  ChevronLeft,
  ChevronRight,
  User,
  LogOut,
  Award,
  IdCard,
} from "lucide-react";
import { useGetMeQuery, useLogoutMutation } from "../../../generated/graphql";
import { Box } from "@mui/material";

interface MenuItem {
  title: string;
  icon: React.ElementType;
  path: string;
}

interface SidebarProps {
  isOpen: boolean;
  onToggle: () => void;
  isMobile: boolean;
}

const menuItems: MenuItem[] = [
  { title: "Home", icon: Home, path: "/" },
  { title: "Tasks", icon: ListTodo, path: "/dashboard/tasks" },
  { title: "Completed", icon: CheckSquare, path: "/dashboard/completed" },
  // { title: "Leaderboard", icon: Trophy, path: "/dashboard/leaderboard" },
];

const Sidebar: React.FC<SidebarProps> = ({ isOpen, onToggle, isMobile }) => {
  const [activeItem, setActiveItem] = useState<string>(menuItems[0].title);
  const location = useLocation();
  const navigate = useNavigate();
  const [logoutMutation] = useLogoutMutation();
  const { data, loading, error } = useGetMeQuery();

  // const [user, setUser] = useState<any>(null);/ // State to store user data

  useEffect(() => {
    const currentItem = menuItems.find(
      (item) => item.path === location.pathname
    );
    if (currentItem) {
      setActiveItem(currentItem.title);
    }
  }, [location]);

  // useEffect(() => {
  //   if (data && data.getMe) {
  //     setUser(data.getMe); // Set user data when available
  //   }
  // }, [data]);

  const handleLogout = async () => {
    try {
      await logoutMutation();
      navigate("/auth");
    } catch (err) {
      console.error("Logout failed:", err);
    }
  };

  const sidebarVariants = {
    expanded: { width: "20rem" },
    collapsed: { width: "5rem" },
  };

  const itemVariants = {
    expanded: { opacity: 1, x: 0 },
    collapsed: { opacity: 0, x: -20 },
  };

  const renderUserData = () => {
    if (loading) return <p className="text-sm text-indigo-300">Loading...</p>;
    if (error)
      return <p className="text-sm text-red-400">Error: {error.message}</p>;
    if (!data?.getMe)
      return <p className="text-sm text-gray-400">User not found</p>;

    return (
      <div className="w-full text-center">
        <h2 className="text-xl font-bold truncate max-w-full mb-3">
          {data.getMe.username}
        </h2>
        <div className="bg-indigo-700/50 rounded-xl p-4 shadow-inner">
          <div className="flex items-center justify-center mb-3">
            <Award size={20} className="text-pink-400 mr-2" />
            <p className="text-indigo-100 text-sm font-medium">
              Points:{" "}
              <span className="text-white font-bold">
                {data.getMe.points_scored}
              </span>
            </p>
          </div>
          <div className="flex items-center justify-center">
            <IdCard size={18} className="text-violet-400 mr-2" />
            <p className="text-indigo-200 text-xs truncate max-w-full">
              ID: {data.getMe.saarang_id}
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Box
      component={motion.div}
      className={`bg-gradient-to-b from-indigo-900 via-indigo-800 to-purple-900 text-white h-screen fixed left-0 top-0 overflow-hidden ${
        isMobile && !isOpen ? "w-0" : ""
      } transition-all duration-300 ease-in-out z-40`}
      initial="expanded"
      animate={isOpen ? "expanded" : "collapsed"}
      variants={sidebarVariants}
    >
      <div className="flex flex-col h-full">
        <div className="flex items-center justify-between p-4">
          <AnimatePresence>
            {isOpen && (
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -20 }}
                transition={{ duration: 0.2 }}
                className="flex items-center space-x-2"
              >
                <img src="/favicon.ico" alt="Logo" className="w-8 h-8" />
                <span className="text-xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-violet-500">
                  SAARANG'25
                </span>
              </motion.div>
            )}
          </AnimatePresence>
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            onClick={onToggle}
            className="p-2 rounded-full bg-indigo-700 hover:bg-indigo-600 transition-colors duration-200"
          >
            {isOpen ? <ChevronLeft size={24} /> : <ChevronRight size={24} />}
          </motion.button>
        </div>

        <motion.div
          className={`flex flex-col items-center mt-4 mb-6 ${
            isOpen ? "" : "px-2"
          }`}
          animate={isOpen ? "expanded" : "collapsed"}
        >
          <div
            className={`${
              isOpen ? "w-20 h-20" : "w-12 h-12"
            } bg-gradient-to-br from-pink-500 to-violet-500 rounded-full flex items-center justify-center overflow-hidden transition-all duration-300`}
          >
            <User size={isOpen ? 40 : 24} className="text-white" />
          </div>
          <AnimatePresence>
            {isOpen && (
              <motion.div
                variants={itemVariants}
                initial="collapsed"
                animate="expanded"
                exit="collapsed"
                transition={{ duration: 0.2 }}
                className="text-center mt-2"
              >
                {renderUserData()}
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>

        <nav className="flex-1 mt-6 px-2">
          <ul className="space-y-2">
            {menuItems.map((item) => (
              <motion.li
                key={item.title}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <Link
                  to={item.path}
                  className={`flex items-center px-4 py-3 rounded-lg transition-all duration-200
                    ${
                      activeItem === item.title
                        ? "bg-gradient-to-r from-pink-500 to-violet-500 text-white shadow-lg"
                        : "text-gray-300 hover:bg-indigo-700"
                    }
                    ${!isOpen ? "justify-center" : ""}
                  `}
                  onClick={() => setActiveItem(item.title)}
                >
                  <item.icon size={24} />
                  <AnimatePresence>
                    {isOpen && (
                      <motion.span
                        variants={itemVariants}
                        initial="collapsed"
                        animate="expanded"
                        exit="collapsed"
                        transition={{ duration: 0.2 }}
                        className="ml-4"
                      >
                        {item.title}
                      </motion.span>
                    )}
                  </AnimatePresence>
                </Link>
              </motion.li>
            ))}
          </ul>
        </nav>

        <div className="p-4">
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="w-full py-2 px-4 bg-red-600 text-white rounded-lg hover:bg-red-500 transition-colors duration-200 flex items-center justify-center"
            onClick={handleLogout}
          >
            <LogOut size={20} />
            <AnimatePresence>
              {isOpen && (
                <motion.span
                  variants={itemVariants}
                  initial="collapsed"
                  animate="expanded"
                  exit="collapsed"
                  transition={{ duration: 0.2 }}
                  className="ml-2"
                >
                  Sign Out
                </motion.span>
              )}
            </AnimatePresence>
          </motion.button>
        </div>
      </div>
    </Box>
  );
};

export default Sidebar;
