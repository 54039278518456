import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
const navButtons = [
  { label: "Ambassador", path: "/" },
  { label: "Milans", path: "/milans" },
  { label: "Accommodation", path: "/accommodation" },
];


const Footer = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const handleNavigation = (path: any) => {
    navigate(path);
    setIsMenuOpen(false);
  };
  return (
    <div className="flex items-center justify-between bg-[#0c1327]  w-full pt-8 flex-col opacity-80 z-10  border-t-2 border-[#ffffff]">
      <div className="flex lg:flex-row flex-col items-start lg:justify-evenly w-full lg:px-2 justify-center  pb-4 opacity-80">
        <div className="flex flex-col items-center  text-white lg:text-3xl justify-start text-xl max-md:pt-4 w-full">
          <h1 className="flex lg:text-start max-md:w-full font-semibold max-md:justify-center text-xl text-white py-4">
            Follow us
          </h1>
          <div className="flex flex-row space-x-3 items-center justify-start">
          <a href="https://www.instagram.com/saarang_iitmadras/?hl=en">
            <img src="/instagram.svg" alt="Twitter Logo" className="w-5" />
          </a>
          <a href="https://x.com/i/flow/login?redirect_after_login=%2Fsaarang_iitm">
            <img src="/twitter.svg" alt="Twitter Logo" className="w-5" />
          </a>
          <a href="https://www.youtube.com/@iitmsaarang">
            <img src="/Youtube.svg" alt="Twitter Logo" className="w-6" />
          </a>
          <a href="https://www.linkedin.com/company/saarang/?originalSubdomain=in">
            <img src="/linkdin.svg" alt="Twitter Logo" className=" w-4" />
          </a>
          </div>
        </div>

        <div className="flex-col flex items-center justify-center w-full max-md:pt-4">
        <h1 className="flex lg:text-center max-md:w-full font-semibold max-md:justify-center text-xl text-white py-4 whitespace-nowrap">
            Quik links
          </h1>
          <nav className="flex flex-col justify-center">
            {navButtons.map((button) => (
              <button
                key={button.label}
                onClick={() => handleNavigation(button.path)}
                className="flex items-center space-x-2 text-white  px-4  rounded-lg  text-base justify-center"
              >
                <span>{button.label}</span>
              </button>
            ))}
          </nav>
         </div>

        <div className="flex flex-col items-center  text-white lg:text-3xl justify-center text-xl max-md:pt-4 w-full">
          <h1 className="text-center w-full font-semibold  text-xl py-4">
            Contact us
          </h1>
          <div className="flex justify-center flex-col text-white w-full">
            <div className="flex flex-row items-center justify-center">
              <img src="mail.svg" alt="" className="w-4 " />
              <p className="text-base font-light px-2 tracking-wide flex items-end">
                studentrelations@saarang.org
              </p>
            </div>
            <div className="flex flex-row items-center justify-center space-x-2">
  <img
    src="/contact.svg"
    alt="Contact icon"
    className="w-6 h-6" 
  />
  <p className="text-base font-light tracking-wide text-center">
    <span className="block">GiriRaghav: 9940544153,</span>
    <span className="block">Suprasidh: 9353662554,</span>
    <span className="block">Naveen: 9150857069</span>
  </p>
</div>

          </div>
        </div>
      </div>

      <div className="flex items-center justify-center w-full pt-2 pb-2 text-white opacity-50 text-l  text-center">
  <span className="text-center">Designed and Developed by Saarang DevOps</span>
</div>

    </div>
  );
};

export default Footer;
