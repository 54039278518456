import React from 'react';
// import 

type Benefit = {
  id: string;
  title: string;
  text: string[];
  backgroundURL: string;
};
const benefits:Benefit[] = [
  {
    id: '0',
    title: "1-3 SAs",
    text: [
      "Internship certificate  of Excellence",
      "A few nights' tickets and accommodation",
      "Merch"
    ],
    backgroundURL: "card-1.svg",
  },
  {
    id: '1',
    title: "4-6 SAs",
    text: [
      "Internship certificate  of Excellence",
      "1 pro show night ticket and accommodation",
      "Merch"
    ],
    backgroundURL: "card-2.svg",
  },
  {
    id: '2',
    title: "7-10 SAs",
    text: [
      "Internship certificate  of Excellence",
      "1 night's accommodation",
      "Merch"
    ],
    backgroundURL: "card-3.svg",
  },
  {
    id: '3',
    title: "11-25 SAs",
    text: [
      "Internship Certificate of Excellence"
    ],
    backgroundURL: "card-4.svg",
  },
  {
    id: '4',
    title: "26-50 SAs",
    text: [
      "Internship certificate of participation"
    ],
    backgroundURL: "card-1.svg",
  },
];

const Benefits = () => {
  return (
    <div className='md:max-w-md lg:max-w-full text-center mt-4 lg:mt-8'>
      <div className='max-w-[50rem] mx-auto py-12 lg:py-20 text-white font-extrabold text-4xl font-sans'>
        <h1>Incentives for Top Performers</h1>
      </div>
      <div className='w-full flex flex-wrap gap-10 mb-10 items-center justify-center'>
        {benefits.map((item) => (
          <div 
            key={item.id} 
            className='block relative p-0.5 bg-no-repeat bg-[length:100%_100%] md:w-[16rem] w-[20rem] items-center h-64'
            style={{
              backgroundImage: `url(${item.backgroundURL})`,
            }}
          >
            <div className='relative z-2 flex flex-col min-h-[12rem] p-[2.4rem] pointer-events-none'>
              <h5 className='text-white mb-5 h2 font-bold tracking-wide text-xl'> 
                {item.title}
              </h5>
              <ul className=' text-neutral-300 text-left'>
                {item.text.map((point, index) => (
                  <div className='flex items-start space-x-2'>
                  <img src="check.svg" alt="" className='w-4 mt-[6px]'/>
                  <li key={index}>{point}</li>
                  </div>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Benefits;
