import React from 'react'

const About = () => {
  return (
    <div className='flex flex-col justify-center items-center md:h-[55vh] h-[90vh] w-full lg:mt-8'>
      <div className='pb-24'>
      <div className='font-sans font-bold text-[40px] md:text-5xl lg:text-6xl text-white tracking-wider text-2xl text-center'>
       <h1 className='max-lg:leading-10'>About Student  <span className='bg-gradient-to-r from-[#393BB2] to-[#E2CBFF] bg-clip-text text-transparent'>Ambassadors Program</span>
       </h1>
       </div>
       <div className=' font-sans md:text-2xl text-center mt-12 text-gray-400 space-y-5 tracking-wide md:px-28 px-4 text-xl'><p>Join the Saarang Student Ambassador program—a unique national-level marketing internship designed for cultural champs like you! As a Student Ambassador, you’ll represent Saarang, IIT Madras' prestigious cultural fest, on your campus, acting as a vital link between us and your college community.
       </p>
      
       <p>
       This program is not just about representing Saarang; it’s about honing your marketing and leadership skills, enhancing your resume, and expanding your professional network. Don’t miss this opportunity to make an impact while gaining invaluable experience! Apply now and start your journey with Saarang!

       </p>
       </div>
       </div>
        
      </div>
  )
}

export default About
