import React, { useEffect, useContext } from "react";
import { useGetMeQuery } from "../generated/graphql";
import PortalContext from "./portalContext";
import client from "../apolloClient";

interface UserContext {
  state: any;
  signUp: (_user: {
    user_id: string;
    positions: string;
    username: string;
  }) => void;
  signIn: (_user: {
    user_id: string;
    positions: string;
    username: string;
  }) => void;
  signOut: () => void;
}

const AuthContext = React.createContext<UserContext | null>(null);

function AuthContextProvider(props: {
  children:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
}) {
  const { data, loading, error } = useGetMeQuery({
    fetchPolicy: "no-cache",
  });
  // console.log(data, "context data");

  const portalContext = useContext(PortalContext);

  const [state, dispatch] = React.useReducer(
    (
      prevState: any,
      action: {
        type: any;
        user?:
          | { user_id: string; positions: string; username: string }
          | undefined;
      }
    ) => {
      switch (action.type) {
        case "RESTORE_TOKEN":
          return {
            ...prevState,
            user: action.user,
            isLoading: false,
          };
        case "SIGN_IN":
          return {
            ...prevState,
            user: action.user,
            isLoading: false,
          };
        case "SIGN_UP":
          return {
            ...prevState,
            user: action.user,
            isLoading: false,
          };
        case "SIGN_OUT":
          return {
            ...prevState,
            user: undefined,
            isLoading: false,
          };
      }
    },
    {
      isLoading: true,
      user: null,
    }
  );

  React.useEffect(() => {
    if (error) dispatch({ type: "SIGN_OUT" });
    if (!loading && data?.getMe)
      dispatch({
        type: "RESTORE_TOKEN",
        user: data.getMe,
      });
  }, [data, loading, error]);

  const authContext = React.useMemo(
    () => ({
      state,
      signIn: (_user: {
        user_id: string;
        positions: string;
        username: string;
      }) => {
        dispatch({
          type: "SIGN_IN",
          user: _user,
        });
        client.resetStore();
      },
      signUp: (_user: {
        user_id: string;
        positions: string;
        username: string;
      }) => {
        dispatch({
          type: "SIGN_UP",
          user: _user,
        });
        client.resetStore();
        // window.location.href = "/verifyEmail";
      },
      signOut: () => {
        dispatch({ type: "SIGN_OUT" });
        window.location.reload();
      },
    }),
    [state]
  );
  useEffect(() => {
    if (data?.getMe) {
      const { user_id, positions, username } = data.getMe;
      dispatch({
        type: "RESTORE_TOKEN",
        user: { user_id, positions, username },
      });
    }
  }, [data]);

  return (
    <AuthContext.Provider value={authContext}>
      {props.children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
export { AuthContextProvider };
