import React, { useState, useEffect, createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Search,
  ChevronRight,
  X,
  Calendar,
  Sun,
  Moon,
  FileText,
  User,
  CheckCircle,
  XCircle,
  School,
  Clock,
  Sparkles,
  ToggleLeft,
} from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import {
  useGetSubmissionsQuery,
  useApproveSubmissionMutation,
} from "../../../../generated/graphql";
import toast, { Toaster } from "react-hot-toast";

const ThemeContext = createContext({
  isDarkMode: false,
  toggleTheme: () => {},
});

interface Submission {
  __typename?: "Submission";
  user: {
    __typename?: "users";
    email_primary: string;
    college?: string | null;
    username: string;
    user_id: string;
  };
  files: string[];
  verification_status: boolean;
  created_at: string;
  id: string;
  task: {
    __typename?: "tasks";
    task_name: string;
    task_desc: string;
  };
}

const SubmissionRibbon: React.FC<{
  submission: Submission;
  onClick: (submission: Submission) => void;
}> = ({ submission, onClick }) => {
  const { isDarkMode } = useContext(ThemeContext);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.3 }}
      className={`flex items-center p-6 mb-4 rounded-xl shadow-lg cursor-pointer transition-all duration-300 transform hover:-translate-y-1 hover:shadow-xl ${
        isDarkMode
          ? "bg-gray-800 hover:bg-gray-700 text-white"
          : "bg-white hover:bg-gray-50 text-gray-800"
      }`}
      onClick={() => onClick(submission)}
    >
      <div className="flex-grow flex items-center space-x-6">
        <div
          className={`w-16 h-16 rounded-full flex items-center justify-center ${
            submission.verification_status ? "bg-green-100" : "bg-red-100"
          }`}
        >
          {submission.verification_status ? (
            <CheckCircle className="text-green-500" size={32} />
          ) : (
            <XCircle className="text-red-500" size={32} />
          )}
        </div>
        <div>
          <h3 className="text-xl font-semibold mb-1">
            {submission.task.task_name}
          </h3>
          <div className="flex items-center space-x-4 text-sm">
            <span className={isDarkMode ? "text-gray-300" : "text-gray-600"}>
              <User size={16} className="inline mr-1" />
              {submission.user.username}
            </span>
            <span className={isDarkMode ? "text-gray-300" : "text-gray-600"}>
              <Calendar size={16} className="inline mr-1" />
              {new Date(submission.created_at).toLocaleDateString()}
            </span>
          </div>
        </div>
      </div>
      <div className="flex items-center space-x-4">
        <span
          className={`px-3 py-1 rounded-full text-sm ${
            submission.verification_status
              ? "bg-green-100 text-green-800"
              : "bg-red-100 text-red-800"
          }`}
        >
          {submission.verification_status ? "Verified" : "Pending"}
        </span>
        <ChevronRight
          className={`${isDarkMode ? "text-gray-400" : "text-gray-400"}`}
          size={24}
        />
      </div>
    </motion.div>
  );
};

const SubmissionPopup: React.FC<{
  submission: Submission;
  onClose: () => void;
  onVerify: (submissionId: string) => void;
}> = ({ submission, onClose, onVerify }) => {
  const { isDarkMode } = useContext(ThemeContext);
  const [points_awarded, setPointsAwarded] = useState<number>(0);
  const [approveSubmission] = useApproveSubmissionMutation();
  const { refetch } = useGetSubmissionsQuery();
  const handleVerifySubmission = async () => {
    const data = {
      sub_id: submission.id,
      points: points_awarded === null ? 0 : points_awarded,
    };
    console.log(data);

    try {
      await approveSubmission({
        variables: {
          data: data,
        },
      });
      toast.success("Submission verified successfully!");
      await refetch();
      onVerify(submission.id);
      onClose();
    } catch (error) {
      toast.error("Failed to verify submission. Please try again.");
      console.error("Error verifying submission:", error);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
    >
      <motion.div
        initial={{ scale: 0.9, opacity: 0, y: 50 }}
        animate={{ scale: 1, opacity: 1, y: 0 }}
        exit={{ scale: 0.9, opacity: 0, y: 50 }}
        transition={{ type: "spring", damping: 25, stiffness: 500 }}
        className={`${
          isDarkMode ? "bg-gray-800" : "bg-white"
        } rounded-2xl w-11/12 h-5/6 max-w-6xl max-h-[90vh] overflow-hidden shadow-2xl flex flex-col`}
      >
        {/* Header */}
        <div className={`p-6 ${isDarkMode ? "bg-gray-700" : "bg-gray-100"}`}>
          <div className="flex justify-between items-center">
            <h2
              className={`text-3xl font-bold ${
                isDarkMode ? "text-white" : "text-gray-800"
              }`}
            >
              {submission.task.task_name}
            </h2>
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onClick={onClose}
              className={`p-2 rounded-full ${
                isDarkMode
                  ? "bg-gray-600 text-gray-300 hover:bg-gray-500"
                  : "bg-gray-200 text-gray-600 hover:bg-gray-300"
              } transition-colors duration-200`}
            >
              <X size={24} />
            </motion.button>
          </div>
          <div
            className={`mt-2 ${isDarkMode ? "text-gray-300" : "text-gray-600"}`}
          >
            <Clock size={16} className="inline-block mr-2" />
            <span>
              Submitted: {new Date(submission.created_at).toLocaleString()}
            </span>
          </div>
        </div>

        {/* Scrollable Content */}
        <div className="flex-grow overflow-y-auto">
          <div className="p-6 space-y-6">
            {/* User Info */}
            <div
              className={`p-4 rounded-lg ${
                isDarkMode ? "bg-gray-700" : "bg-gray-100"
              }`}
            >
              <h3
                className={`text-xl font-semibold mb-4 ${
                  isDarkMode ? "text-white" : "text-gray-800"
                }`}
              >
                Submitter Information
              </h3>
              <div
                className={`grid grid-cols-1 md:grid-cols-2 gap-4 ${
                  isDarkMode ? "text-gray-300" : "text-gray-600"
                }`}
              >
                <div className="flex items-center space-x-2">
                  <User size={20} />
                  <span>{submission.user.username}</span>
                </div>
                <div className="flex items-center space-x-2">
                  <FileText size={20} />
                  <span>{submission.user.email_primary}</span>
                </div>
                <div className="flex items-center space-x-2">
                  <School size={20} />
                  <span>{submission.user.college || "N/A"}</span>
                </div>
              </div>
            </div>

            {/* Submission Details */}
            <div
              className={`p-4 rounded-lg ${
                isDarkMode ? "bg-gray-700" : "bg-gray-100"
              }`}
            >
              <h3
                className={`text-xl font-semibold mb-4 ${
                  isDarkMode ? "text-white" : "text-gray-800"
                }`}
              >
                Submission Details
              </h3>
              <div
                className={`space-y-4 ${
                  isDarkMode ? "text-gray-300" : "text-gray-600"
                }`}
              >
                <div className="flex items-center space-x-2">
                  <span className="font-semibold">Verification Status:</span>
                  <span
                    className={`px-2 py-1 rounded-full text-white ${
                      submission.verification_status
                        ? "bg-green-500"
                        : "bg-red-500"
                    }`}
                  >
                    {submission.verification_status
                      ? "Verified"
                      : "Not Verified"}
                  </span>
                </div>
                <div>
                  <span className="font-semibold mb-2 block">Files:</span>
                  <div className="flex flex-wrap gap-2">
                    {submission.files.map((file, index) => (
                      <a
                        key={index}
                        href={file}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`inline-flex items-center px-3 py-1 rounded-full text-sm ${
                          isDarkMode
                            ? "bg-blue-900 text-blue-200"
                            : "bg-blue-100 text-blue-800"
                        }`}
                      >
                        <motion.span whileHover={{ scale: 1.05 }}>
                          File{index + 1}
                        </motion.span>
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            {/* Task */}
            <div
              className={`p-4 rounded-lg ${
                isDarkMode ? "bg-gray-700" : "bg-gray-100"
              } flex flex-col items-center`}
            >
              <div
                className={`w-full max-w-4xl ${
                  isDarkMode ? "text-white" : "text-gray-800"
                }`}
              >
                <div className="flex flex-col mb-4">
                  <div className="mb-4">
                    <h3 className="text-xl font-semibold mb-2">Task Name</h3>
                    <p>{submission.task.task_name}</p>
                  </div>
                  <div>
                    <h3
                      className={`text-xl font-semibold mb-2 ${
                        isDarkMode ? "text-white" : "text-gray-800"
                      }`}
                    >
                      Task Description
                    </h3>
                    <p
                      className={`${
                        isDarkMode ? "text-gray-300" : "text-gray-600"
                      } whitespace-pre-wrap`}
                    >
                      {submission.task.task_desc}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Footer */}
          </div>
        </div>

        {/* Footer */}
        <div
          className={`p-4 ${
            isDarkMode ? "bg-gray-700" : "bg-gray-100"
          } flex justify-between items-center`}
        >
          <div className="flex items-center space-x-2">
            <Sparkles
              size={20}
              className={isDarkMode ? "text-yellow-400" : "text-yellow-600"}
            />
            <span className={isDarkMode ? "text-gray-300" : "text-gray-600"}>
              Points Awarded:
            </span>
            <input
              type="number"
              value={points_awarded}
              onChange={(e) => setPointsAwarded(+e.target.value)}
              className={`w-16 px-2 py-1 rounded-lg ${
                isDarkMode
                  ? "bg-gray-800 text-gray-300"
                  : "bg-gray-200 text-gray-600"
              } focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-200`}
            />
          </div>
          {!submission.verification_status && (
            <motion.button
              onClick={handleVerifySubmission}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className={`px-4 py-2 rounded-lg ${
                isDarkMode
                  ? "bg-blue-600 hover:bg-blue-700 text-white"
                  : "bg-blue-500 hover:bg-blue-600 text-white"
              } transition-colors duration-200`}
            >
              Verify Submission
            </motion.button>
          )}
        </div>
      </motion.div>
    </motion.div>
  );
};

const SubmissionsManagement: React.FC = () => {
  const [submissions, setSubmissions] = useState<Submission[]>([]);
  const [filteredSubmissions, setFilteredSubmissions] = useState<Submission[]>(
    []
  );
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedSubmission, setSelectedSubmission] =
    useState<Submission | null>(null);
  const [isDarkMode, setIsDarkMode] = useState<boolean>(true);
  const [showVerified, setShowVerified] = useState<boolean | null>(null);
  const navigate = useNavigate();

  const { data: submissionData, refetch } = useGetSubmissionsQuery();
  const generateCSV = (submissions: Submission[]) => {
    const csvRows = [
      ["Submission Id", "Task Name", "Task Description"],
      ...submissions.map((submission) => [
        submission.id || "",
        submission.task.task_name || "",
        submission.task.task_desc || "",
      ]),
    ];

    return csvRows.map((row) => row.join(",")).join("\n");
  };
  const handleDownloadCSV = () => {
    const csv = generateCSV(submissions);
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "tasks.csv";
    a.click();
    window.URL.revokeObjectURL(url);
  };
  useEffect(() => {
    if (submissionData?.getSubmissions) {
      setSubmissions(submissionData.getSubmissions as Submission[]);
    }
  }, [submissionData]);

  useEffect(() => {
    const filtered = submissions.filter(
      (submission) =>
        (submission.task.task_name
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
          submission.user.username
            .toLowerCase()
            .includes(searchTerm.toLowerCase())) &&
        (showVerified === null ||
          submission.verification_status === showVerified)
    );
    setFilteredSubmissions(filtered);
  }, [searchTerm, submissions, showVerified]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  const toggleVerificationFilter = () => {
    setShowVerified((prev) => {
      if (prev === null) return true;
      if (prev === true) return false;
      return null;
    });
  };

  const handleVerifySubmission = async (submissionId: string) => {
    const updatedSubmissions = submissions.map((sub) =>
      sub.id === submissionId ? { ...sub, verification_status: true } : sub
    );
    setSubmissions(updatedSubmissions);
    await refetch();
  };

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleTheme }}>
      <div
        className={`min-h-screen ${
          isDarkMode ? "bg-gray-900 text-white" : "bg-gray-100 text-gray-800"
        } pt-24 mt-20`}
      >
        <Toaster position="top-right" />
        <motion.div
          initial={{ y: -100 }}
          animate={{ y: 0 }}
          className={`fixed top-0 left-0 right-0 ${
            isDarkMode ? "bg-gray-800" : "bg-white"
          } shadow-lg z-10`}
        >
          <div className="container mx-auto px-6 py-4">
            <div className="flex justify-between items-center">
              <h1 className="text-4xl font-extrabold text-gray-900 dark:text-gray-100 bg-gradient-to-r from-blue-500 to-teal-500 text-transparent bg-clip-text p-2">
                Submissions Management
              </h1>
              <div className="flex items-center space-x-4">
                <motion.button
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  onClick={toggleVerificationFilter}
                  className={`p-3 rounded-full ${
                    isDarkMode
                      ? "bg-gray-700 text-blue-300"
                      : "bg-gray-200 text-blue-800"
                  } transition-colors duration-200`}
                >
                  {showVerified === null ? (
                    <ToggleLeft size={24} />
                  ) : showVerified ? (
                    <CheckCircle size={24} />
                  ) : (
                    <XCircle size={24} />
                  )}
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  onClick={toggleTheme}
                  className={`p-3 rounded-full ${
                    isDarkMode
                      ? "bg-gray-700 text-yellow-300"
                      : "bg-gray-200 text-gray-800"
                  } transition-colors duration-200`}
                >
                  {isDarkMode ? <Sun size={24} /> : <Moon size={24} />}
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => navigate("/admin")}
                  className={`px-4 py-2 rounded-lg ${
                    isDarkMode
                      ? "bg-gray-600 hover:bg-gray-700 text-white"
                      : "bg-gray-500 hover:bg-gray-600 text-white"
                  } transition-colors duration-200`}
                >
                  Back to Admin
                </motion.button>
              </div>
            </div>
            <div className="mt-6 relative">
              <input
                type="text"
                placeholder="Search submissions..."
                value={searchTerm}
                onChange={handleSearch}
                className={`w-full p-3 pl-12 rounded-lg border text-lg ${
                  isDarkMode
                    ? "bg-gray-700 text-white border-gray-600"
                    : "bg-white text-gray-800 border-gray-300"
                } focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-200`}
              />
              <Search
                size={24}
                className={`absolute left-4 top-1/2 transform -translate-y-1/2 ${
                  isDarkMode ? "text-gray-400" : "text-gray-500"
                }`}
              />
            </div>
          </div>
        </motion.div>

        <div className="container mx-auto px-6 pt-8">
          <AnimatePresence>
            {filteredSubmissions.map((submission, index) => (
              <SubmissionRibbon
                key={`${submission.user.user_id}-${index}`}
                submission={submission}
                onClick={(selected) => setSelectedSubmission(selected)}
              />
            ))}
          </AnimatePresence>
        </div>

        <AnimatePresence>
          {selectedSubmission && (
            <SubmissionPopup
              submission={selectedSubmission}
              onClose={() => setSelectedSubmission(null)}
              onVerify={handleVerifySubmission}
            />
          )}
        </AnimatePresence>
      </div>
    </ThemeContext.Provider>
  );
};

export default SubmissionsManagement;
