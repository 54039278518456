import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Ambassador from "./pages/sa/ambassador/ambassador";
import AuthPage from "./pages/auth/auth";
import ForgotPassword from "./pages/auth/forgotpassword/forgot";
import ResetPassword from "./pages/auth/forgotpassword/resetpassword";
import Dashboard from "./pages/sa/ambassador/Dashboard";
import Tasks from "./combonents/sa/Tasks/Tasks";
import CompletedTasks from "./combonents/sa/Tasks/Completed";
import Leaderboard from "./combonents/sa/leaderboard/Leaderboard";

import Admin from "./pages/sa/admin/admin";
import SubmissionsManagement from "./pages/sa/admin/submissions/submissions";
import TaskManagement from "./pages/sa/admin/tasks/adminTasks";
import { useGetMeQuery } from "./generated/graphql";
import Milan from "./pages/milan/milan";
import City from "./pages/milan/city"; //
import Events from "./pages/milan/events/events";
import MilanAdmin from "./pages/milan/admin/MilanAdmin";
import UserDashboard from "./pages/milan/dashboard/userDashboard";
import Accommodation from "./combonents/dummy_acommodation/Acommodation";
function App() {
  const { data, error, loading } = useGetMeQuery();
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Ambassador />} />
          <Route path="/accommodation" element={<Accommodation />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/resetpassword/:id1/:id2" element={<ResetPassword />} />
          <Route path="/test" element={<ResetPassword />} />

          <Route path="auth" element={<AuthPage />} />
          <Route path="/milans" element={<Milan />} />
          {/* Dynamic route for cities */}
          <Route path="milans/:city" element={<Events />} />
          <Route path="milans/dashboard" element={<UserDashboard />} />
          {/* </Route> */}
          {data?.getMe.positions[97] === "1" && (
            <>
              {" "}
              <Route path="/dashboard" element={<Dashboard />}>
                <Route path="tasks" element={<Tasks />} />
                <Route path="completed" element={<CompletedTasks />} />
                <Route path="leaderboard" element={<Leaderboard />} />
              </Route>
            </>
          )}

          {data?.getMe.positions[7] === "1" && (
      <>
        <Route path="/admin" element={<Admin />} />
        <Route path="/admin/milans" element={<MilanAdmin />} />

        <Route path="/admin/tasks" element={<TaskManagement />} />
        <Route path="/admin/submissions" element={<SubmissionsManagement />} />
      </>
    )}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
