import React from 'react'
import { MovingBorder } from '../ui/MovingBorders';
import { Button } from '../ui/MovingBorders';

type Item = {
  id: number;
  title: string;
  img: string;
  description: string;
};

const items: Item[] = [
  {
    id: 1,
    title: "Social Media Promotion",
    img: "https://images.pexels.com/photos/607812/pexels-photo-607812.jpeg?auto=compress&cs=tinysrgb&w=600",
    description: " Share and like Saarang's social media posts.",
  },
  {
    id: 2,
    title: "Email Campaigns",
    img: "https://images.pexels.com/photos/193003/pexels-photo-193003.jpeg?auto=compress&cs=tinysrgb&w=600",
    description: " Conduct email campaigns in your college."
  },
  {
    id: 3,
    title: "Innovative Marketing",
    img: "https://images.pexels.com/photos/7688336/pexels-photo-7688336.jpeg?auto=compress&cs=tinysrgb&w=600",
    description: "Develop and implement effective social media strategies ",
  },
  {
    id: 4,
    title: "Publicize Events",
    img: "https://images.pexels.com/photos/2034851/pexels-photo-2034851.jpeg?auto=compress&cs=tinysrgb&w=600",
    description: "Promote nationwide events conducted by Saarang ",
  },
  {
    id: 5,
    title: "Campus Representative",
    img: "https://images.pexels.com/photos/3762800/pexels-photo-3762800.jpeg?auto=compress&cs=tinysrgb&w=600",
    description: "Be the FACE OF SAARANG on your campus",
  },
  {
    id: 6,
    title: "Marketing and Outreach",
    img: "https://images.pexels.com/photos/6224/hands-people-woman-working.jpg?auto=compress&cs=tinysrgb&w=600",
    description: "Develop and execute campus-specific campaigns ",
  },
  {
    id: 7,
    title: "Coordinate Milans",
    img: "https://images.pexels.com/photos/2263436/pexels-photo-2263436.jpeg?auto=compress&cs=tinysrgb&w=600",
    description: "Coordinate and conduct Milans in your college—a mini Saarang event for publicity",
  },
  {
    id: 8,
    title: "Boost Sales",
    img: "https://images.pexels.com/photos/3184292/pexels-photo-3184292.jpeg?auto=compress&cs=tinysrgb&w=600",
    description: "Help promote and sell Saarang merchandise. ",
  }
];
const Roles = () => {
  return (
    <div id="testimonials" className="py-20">
    <h1 className=" text-white  text-center text-[40px] md:text-5xl lg:text-6xl font-bold font-sans">
      Roles and 
      <span className=" gradient-text"> Responsiblities</span>
    </h1>

    <div className="w-full mt-12 grid lg:grid-cols-3 grid-cols-1 gap-10 md:grid-cols-2">
      {items.map((card)=>(
        <Button 
        
        duration={Math.floor(Math.random() * 10000) + 10000}
        borderRadius="1.75rem"
          
             >
          <div className='flex lg:flex-row flex-col lg:items-center p-3 py-6 md:p-3 lg:p-8 gap-2'>
          <img src={card.img} alt="" className='lg:w-32 md:w-20 w-16 h-auto'/>
          <div className='lg:ms-5'>
            <h1 className='text-start test-xl md:text-2xl font-bold font-sans'>
              {card.title}
            </h1 >
            <p className='text-start text-gray-300 mt-3 font-semibold font-sans'>
              {card.description}
            </p>
          </div>
          </div>
          </Button>
        // <div>dsd</div>
      ))}
      
    </div>
    </div>
  )
}

export default Roles
