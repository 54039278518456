import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { format } from "date-fns";
import { useCreateSubmissionMutation } from "../../../generated/graphql";
import {
  Upload,
  X,
  CheckCircle,
  AlertCircle,
  FileText,
  Calendar,
  Clock,
} from "lucide-react";
import AWS from "aws-sdk";

interface Task {
  id: string;
  task_name: string;
  task_desc: string;
  created_at: string;
  deadline: string;
}

interface TaskCardProps {
  task: Task;
  username: string;
}

const TaskCard: React.FC<TaskCardProps> = ({ task, username }) => {
  const [files, setFiles] = useState<File[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);

  const [createSubmission] = useCreateSubmissionMutation();

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
  }, []);
  AWS.config.update({
    accessKeyId: "AKIAVL4JN2MBXZ6S2V5K",
    secretAccessKey: "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",
    region: "ap-south-1",
  });

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth >= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const s3 = new AWS.S3();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
      "image/*": [".png", ".jpg", ".jpeg", ".gif"],
      "text/csv": [".csv"],
    },
    multiple: true,
  });

  const removeFile = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const uploadToS3 = async (file: File, fileName: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      const params = {
        Bucket: "saportal2025",
        Key: fileName,
        Body: file,
        ContentType: file.type,
      };

      const upload = s3.upload(params);

      upload.on("httpUploadProgress", (progress) => {
        const percentage = Math.round((progress.loaded / progress.total) * 100);
        setUploadProgress(percentage);
      });

      upload.send((err, data) => {
        if (err) {
          console.error("Error uploading file:", err);
          reject(err);
        } else {
          console.log("File uploaded successfully. URL:", data.Location);
          resolve(data.Location);
        }
      });
    });
  };

  const handleSubmit = async () => {
    if (files.length === 0) {
      setError("Please select at least one file before submitting.");
      return;
    }

    setUploading(true);
    setError(null);
    setUploadProgress(0);

    try {
      const uploadedFileUrls = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fileName = `${username}_${task.task_name}_${Date.now()}_${file.name}`;
        const url = await uploadToS3(file, fileName);
        console.log("File uploaded:", url);
        uploadedFileUrls.push(url);
        setUploadProgress(((i + 1) / files.length) * 100);
      }

      const result = await createSubmission({
        variables: {
          data: {
            task_id: task.id,
            files: uploadedFileUrls,
          },
        },
      });

      if (result.data?.createSubmission) {
        setSuccess(true);
        setFiles([]);
        window.location.reload()
        // Consider using a more appropriate way to refresh data instead of reloading the page
        // For example, you might want to update local state or refetch data
      } else {
        throw new Error("Failed to create submission");
      }
    } catch (error) {
      console.error("Error creating submission:", error);
      setError("An error occurred while submitting. Please try again");
    } finally {
      setUploading(false);
    }
  };

  const deadlineDate = new Date(task.deadline);
  const isOverdue = deadlineDate < new Date();

  return (
      <div className="bg-gray-800 shadow-xl rounded-2xl overflow-hidden mx-auto w-full sm:max-w-3xl">
        <div className="p-6 sm:p-8">
          <div className="flex flex-col sm:flex-row justify-between items-start mb-6">
            <div>
              <h2 className="text-2xl sm:text-3xl font-bold text-gray-100">
                {task.task_name}
              </h2>
              <p className="text-sm text-gray-400 mt-2 flex items-center">
                <Calendar className="inline mr-2" size={16} />
                Created: {format(new Date(task.created_at), "MMM d, yyyy")}
              </p>
            </div>
            <div
                className={`flex items-center mt-4 sm:mt-0 ${
                    isOverdue
                        ? "text-red-400 bg-red-900"
                        : "text-green-400 bg-green-900"
                } px-4 py-2 rounded-full text-sm font-semibold`}
            >
              <Clock className="mr-2" size={16} />
              {isOverdue ? "Overdue" : "Due"}:{" "}
              {format(deadlineDate, "MMM d, yyyy")}
            </div>
          </div>

          <p className="text-gray-300 mb-6">{task.task_desc}</p>

          <div
              {...getRootProps()}
              className={`border-3 border-dashed rounded-xl p-6 sm:p-8 text-center cursor-pointer transition-all duration-300 ${
                  isDragActive
                      ? "border-blue-500 bg-blue-900"
                      : "border-gray-600 hover:border-gray-500 hover:bg-gray-700"
              }`}
          >
            <input {...getInputProps()} />
            <Upload className="mx-auto text-blue-400 mb-4" size={40} />
            {isMobile ? (
                <p className="text-gray-300 text-lg">
                  {isDragActive
                      ? "Drop the files here"
                      : "Drag & drop files here, or click to select files"}
                </p>
            ) : (
                ""
            )}
            <p className="text-sm text-gray-500 mt-2">
              Supported formats: PDF, DOC, DOCX, PNG, JPG, GIF, CSV
            </p>
          </div>

          {files.length > 0 && (
              <div className="mt-6">
                <h3 className="text-xl font-semibold mb-3 text-gray-200">
                  Selected Files:
                </h3>
                <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
                  {files.map((file, index) => (
                      <div
                          key={`${file.name}-${index}`}
                          className="flex items-center justify-between p-3 bg-gray-700 rounded-lg"
                      >
                        <div className="flex items-center">
                          <FileText size={20} className="text-blue-400 mr-3" />
                          <span className="text-sm font-medium text-gray-300 truncate max-w-[150px]">
                      {file.name}
                    </span>
                        </div>
                        <button
                            onClick={() => removeFile(index)}
                            className="text-red-400 hover:text-red-300 hover:bg-red-900 p-1 rounded-full transition-colors duration-200"
                        >
                          <X size={20} />
                        </button>
                      </div>
                  ))}
                </div>
              </div>
          )}

          {uploading && (
              <div className="mt-6">
                <div className="flex justify-between mb-1">
              <span className="text-base font-medium text-blue-400">
                Uploading
              </span>
                  <span className="text-sm font-medium text-blue-400">
                {uploadProgress}%
              </span>
                </div>
                <div className="w-full bg-gray-700 rounded-full h-2.5">
                  <div
                      className="bg-blue-600 h-2.5 rounded-full transition-all duration-500 ease-out"
                      style={{ width: `${uploadProgress}%` }}
                  ></div>
                </div>
              </div>
          )}

          {error && (
              <div className="mt-6 p-4 bg-red-900 text-red-200 rounded-lg flex items-center">
                <AlertCircle className="mr-3" size={24} />
                <p>{error}</p>
              </div>
          )}

          {success && (
              <div className="mt-6 p-4 bg-green-900 text-green-200 rounded-lg flex items-center">
                <CheckCircle className="mr-3" size={24} />
                <p>Submission created successfully!</p>
              </div>
          )}

          {!isOverdue && (
              <button
                  onClick={handleSubmit}
                  disabled={uploading || files.length === 0}
                  className={`mt-8 w-full py-4 px-6 rounded-lg text-white font-bold text-lg transition-all duration-300 ${
                      uploading || files.length === 0
                          ? "bg-gray-600 cursor-not-allowed"
                          : "bg-blue-600 hover:bg-blue-700 shadow-lg hover:shadow-xl"
                  }`}
              >
                {uploading ? "Uploading..." : "Submit"}
              </button>
          )}
        </div>
      </div>
  );
};

export default TaskCard;