import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useGetLeaderboardQuery } from "../../../generated/graphql";

interface LeaderboardEntry {
  rank: number;
  username: string;
  points_scored: number;
}

const Leaderboard: React.FC = () => {
  const [showAll, setShowAll] = useState(false);
  const [highlightedRank, setHighlightedRank] = useState<number | null>(null);

  const { data, loading, error } = useGetLeaderboardQuery();

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const leaderboardData: LeaderboardEntry[] =
    data?.getLeaderboard.map((entry, index) => ({
      rank: index + 1,
      username: entry.username,
      points_scored: entry.points_scored ?? 0, // Use nullish coalescing to provide a default value
    })) || [];

  const userRank =
    leaderboardData.findIndex((entry) => entry.username === "You") + 1;
  const topPerformers: LeaderboardEntry[] = leaderboardData.slice(0, 3);
  const surroundingPerformers: LeaderboardEntry[] = showAll
    ? leaderboardData.slice(3)
    : leaderboardData.slice(3, 10);

  const medalImages: { [key: number]: string } = {
    1: "/1st.png",
    2: "/2nd.png",
    3: "/3rd.png",
  };

  return (
    <motion.div
      className="flex flex-col items-center space-y-8 p-8 bg-gray-900 text-white min-h-screen"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <h1 className="text-4xl font-bold mb-2">Leaderboard</h1>

      <div className="flex justify-center items-end relative mb-16 w-full max-w-3xl">
        {topPerformers.map((performer, index) => (
          <motion.div
            key={performer.rank}
            className={`mx-4 flex flex-col items-center ${
              index === 1 ? "order-first" : ""
            }`}
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: index * 0.2 }}
          >
            <img
              src={medalImages[performer.rank]}
              alt={`${performer.rank} rank`}
              className={`w-24 lg:w-32 mb-4 ${
                index === 0 ? "w-32 lg:w-40" : ""
              }`}
            />
            <div
              className={`bg-gray-800 p-4 rounded-lg text-center ${
                index === 0 ? "transform scale-110" : ""
              }`}
            >
              <h2 className="text-xl font-semibold">{performer.username}</h2>
              <p className="text-green-400">Score: {performer.points_scored}</p>
            </div>
          </motion.div>
        ))}
      </div>

      <div className="w-full max-w-2xl space-y-4">
        <AnimatePresence>
          {surroundingPerformers.map((performer: LeaderboardEntry) => (
            <motion.div
              key={performer.rank}
              className={`bg-gray-800 p-4 rounded-lg flex justify-between items-center
                ${
                  performer.rank === userRank ? "border-2 border-green-500" : ""
                }
                ${highlightedRank === performer.rank ? "bg-gray-700" : ""}`}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
              onMouseEnter={() => setHighlightedRank(performer.rank)}
              onMouseLeave={() => setHighlightedRank(null)}
            >
              <div className="flex items-center">
                <span className="text-2xl font-bold mr-4">
                  {performer.rank}
                </span>
                <span
                  className={`text-xl ${
                    performer.username === "You"
                      ? "text-green-400 font-bold"
                      : ""
                  }`}
                >
                  {performer.username}
                </span>
              </div>
              <span className="text-xl">Score: {performer.points_scored}</span>
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
    </motion.div>
  );
};

export default Leaderboard;
