import React from "react";
import { useNavigate } from "react-router-dom";
import {
  useGetMeQuery,
  useGetUserMilanRegistrationsQuery,
  useGetMilanEventByIdQuery,
} from "../../../generated/graphql";
import {
  CheckCircleIcon,
  XCircleIcon,
  CalendarIcon,
  MapPinIcon,
  UsersIcon,
  CurrencyIcon,
  ArrowRightCircleIcon,
} from "lucide-react";
import Header from "../../../combonents/sa/header/header";
import Loader from "../../../combonents/Loader/loader";
const UserDashboard = () => {
  const { data: userData } = useGetMeQuery();
  // const userId = "USER_ID";
  const userId = userData?.getMe.user_id;
  const navigate = useNavigate();

  const { data, loading, error } = useGetUserMilanRegistrationsQuery({
    variables: { userId: userId! },
    skip: !userId,
  });

  //   if (!userId)
  //     return <div className="text-red-500">Error loading registrations</div>;
  if (loading)
    return (
      <div className="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] scale-[5]">
        <Loader />
      </div>
    );
  if (error)
    return <div className="text-red-500">Error loading registrations</div>;

  return (
    <div className="bg-slate-900 min-h-screen flex flex-col items-stretch p-8 ">
      <Header />
      <div className="flex justify-between items-center mb-12 mt-[8rem]">
        <h1 className="text-4xl font-bold text-[#6f77fd]">
          Your Registrations
        </h1>
        <button
          onClick={() => navigate("/milans")}
          className="bg-[#6f77fd] hover:bg-[#5a63fd] text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 flex items-center"
        >
          Home
          <ArrowRightCircleIcon className="ml-2" size={20} />
        </button>
      </div>
      <ul className="space-y-8">
        {data?.getUserMilanRegistrations.map((registration) => (
          <RegistrationCard key={registration.id} registration={registration} />
        ))}
      </ul>
    </div>
  );
};

const RegistrationCard = ({ registration }: { registration: any }) => {
  const {
    data: eventData,
    loading,
    error,
  } = useGetMilanEventByIdQuery({
    variables: { getMilanEventByIdId: registration.event_id },
  });

  if (loading)
    return <div className="text-blue-500">Loading event details...</div>;
  if (error)
    return <div className="text-red-500">Error loading event details</div>;

  const event = eventData?.getMilanEventById;

  return (
    <>
      <li className="bg-slate-800 rounded-lg p-6 shadow-md ">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="space-y-4">
            <h2 className="text-2xl font-bold text-[#6f77fd]">{event?.name}</h2>
         {event?.city!=="online" &&   <div className="text-slate-300 flex items-center space-x-2">
              <CalendarIcon size={20} />
              <span>
  {new Date(event?.date).toLocaleDateString("en-GB")}
</span>            </div>}
            <div className="text-slate-300 flex items-center space-x-2">
              <MapPinIcon size={20} />
              {event?.city==="online"?<span>Online Milans</span>:
              <span>
                {event?.city}, {event?.venue}
              </span>}
            </div>
            <div className="text-slate-300 flex items-center space-x-2">
              <CurrencyIcon size={20} />
              <span>Price: ₹{event?.price}</span>
            </div>
            {/* <div className="text-slate-300 flex items-center space-x-2">
              <UsersIcon size={20} />
              <span>
                Amount: ₹{registration.participants * (event?.price || 0)}
              </span>
            </div> */}
          </div>
          <div className="space-y-4">
            <h3 className="text-xl font-semibold text-slate-300">
              Registration Details
            </h3>

            {/* <div className="text-slate-300">
              <span className="font-semibold">Participants:</span>{" "}
              {registration.participants}
            </div> */}
  <div className="text-slate-300 flex items-center space-x-2">
              <span className="font-semibold">Registration Date:</span>
              <span>
  {new Date(registration?.reg_date).toLocaleDateString("en-GB")}
</span>            </div>
            <div className="text-slate-300 flex items-center space-x-2">
              <span className="font-semibold">Registration Successful:</span>
              {registration.verified ? (
                <CheckCircleIcon className="text-green-500" size={20} />
              ) : (
                <XCircleIcon className="text-red-500" size={20} />
              )}
            </div>
          </div>
        </div>
      </li>
    </>
  );
};

export default UserDashboard;
