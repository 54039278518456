import { useSelector } from "react-redux";
import "./ambassador.scss";
import { RootState } from "../../../redux/device/reducers";
import useWindowSize from "../../../redux/device/useWindowSize";
import Hero from "../../../combonents/sa/Hero";
import Roles from "../../../combonents/sa/roles/roles";
import WhyCa from "../../../combonents/sa/whyCa/whyCa";
import About from "../../../combonents/sa/About";
import FAQ from "../../../combonents/sa/faq/faq";
import { useState, useRef, useEffect, useContext } from "react";
import RegisterSa from "../../../combonents/sa/saRegister/saRegister";
import { useGetMeQuery, useLogoutMutation } from "../../../generated/graphql";
import { useNavigate } from "react-router-dom";
import Header from "../../../combonents/sa/header/header";
import Footer from "../../../combonents/sa/footer/Footer";
import AuthContext from "../../../contexts/authContext";
import Popup from "../../../ui-elements/popup/popup";
import Benefits from "../../../combonents/sa/benefits/Benefits";

const Ambassador: React.FC = () => {
  const { device } = useSelector((state: RootState) => state.windowSize);
  const [popup, setPopup] = useState<Boolean>(false);
  const [isOverflowHidden, setIsOverflowHidden] = useState(true); // State to toggle overflow-hidden
  const navigate = useNavigate();
  const { data, error, loading } = useGetMeQuery();
  const whyCaRef = useRef<HTMLDivElement>(null); // Reference to the WhyCa section

  
  const handleRegister = () => {
    console.log(data?.getMe.saarang_id, "me");
    if (data?.getMe) {
      setPopup(true);
    } else {
      navigate("/auth");
    }
  };

  const handleScroll = () => {
    if (whyCaRef.current) {
      const rect = whyCaRef.current.getBoundingClientRect();
      if (rect.top <= window.innerHeight && rect.bottom >= 0) {
        // When WhyCa is in view
        setIsOverflowHidden(false);
      } else {
        setIsOverflowHidden(true);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleClosePopup = () => {
    setPopup(false);
  };

  useWindowSize();
  const authContext = useContext(AuthContext);

  if(!authContext) {
    return <div>Error: Error in logging in.</div>;
  }

  const { state } = authContext;
  return (
    <div
      className={`ambassador-page ${device} ${
        isOverflowHidden ? "overflow-hidden" : ""
      }`}
    >
      <Header />

      <main className="relative bg-black-100 flex justify-center items-center flex-col w-full sm:px-10 px-5">
        <div className={`saregister-wrapper ${popup ? "open" : ""}`}>
          <RegisterSa onClose={handleClosePopup} />
        </div>
        <div className="max-w-7xl w-full">
          <Hero handleRegister={handleRegister} />
        </div>
        <About />
        <Benefits />
        <Roles />
        {device !== "mobile" &&
        <div ref={whyCaRef}>
          <WhyCa />
        </div>
        }
        <FAQ />
      </main>
      <Footer />
    </div>
  );
};

export default Ambassador;
