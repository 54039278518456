import React, { useState, useCallback, useMemo } from "react";
import { useGetUserSubmissionsQuery } from "../../../generated/graphql";
import { motion, AnimatePresence } from "framer-motion";
// Custom hook for animations
const useAnimations = () => ({
  container: {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  },
  item: {
    initial: { y: 20, opacity: 0 },
    animate: { y: 0, opacity: 1 },
    exit: { y: -20, opacity: 0 },
    transition: { type: "spring", stiffness: 300, damping: 30 },
  },
});

// Custom hook for search and filter
const useSearchAndFilter = (submissions: any) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("all");

  const filteredSubmissions = useMemo(() => {
    return submissions.filter(
      (submission: {
        task: { task_name: string };
        verification_status: any;
      }) => {
        const matchesSearch = submission.task.task_name
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
        const matchesFilter =
          filter === "all" ||
          (filter === "verified" && submission.verification_status) ||
          (filter === "pending" && !submission.verification_status);
        return matchesSearch && matchesFilter;
      }
    );
  }, [submissions, searchTerm, filter]);

  return { searchTerm, setSearchTerm, filter, setFilter, filteredSubmissions };
};

// Styled components
const StyledInput = ({ ...props }) => (
  <input
    {...props}
    className="w-full px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-200 transition-colors duration-200"
  />
);

// @ts-ignore
const StyledButton = ({ children, active, ...props }) => (
  <button
    {...props}
    className={`px-4 py-2 rounded-md font-semibold transition-colors duration-200 ${
      active
        ? "bg-blue-500 text-white"
        : "bg-white text-gray-700 border border-gray-300 hover:bg-gray-100"
    }`}
  >
    {children}
  </button>
);

const StyledCard = motion.div;

const CompletedTasks = () => {
  const { data, loading, error } = useGetUserSubmissionsQuery();

  const submissions = data?.getUserSubmissions || [];
  const { searchTerm, setSearchTerm, filter, setFilter, filteredSubmissions } =
    useSearchAndFilter(submissions);
  const animations = useAnimations();

  const [expandedTask, setExpandedTask] = useState(null);

  const toggleTaskExpansion = useCallback((taskId: any) => {
    setExpandedTask((prevId) => (prevId === taskId ? null : taskId));
  }, []);

  if (loading) return <div className="text-gray-500">Loading...</div>;
  if (error) return <div className="text-red-500">Error: {error.message}</div>;
  if (submissions.length === 0) {
    return <div className="text-gray-500">No completed tasks yet.</div>;
  }

  return (
    <motion.div
      className="space-y-6 p-6 bg-gradient-to-b from-indigo-900 via-indigo-800 to-purple-900 rounded-lg shadow-lg m-10"
      {...animations.container}
    >
      <h2 className="text-4xl font-bold text-gray-100 mb-8">Completed Tasks</h2>
      <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 mb-6">
        <div className="flex-grow">
          <StyledInput
            type="text"
            placeholder="Search tasks..."
            value={searchTerm}
            onChange={(e: any) => setSearchTerm(e.target.value)}
            className="bg-gray-800 text-gray-100 placeholder-gray-400 border-gray-700"
          />
        </div>
        <div className="flex space-x-2">
          <StyledButton
            onClick={() => setFilter("all")}
            active={filter === "all"}
            className="bg-gray-700 text-gray-100 hover:bg-gray-600"
          >
            All
          </StyledButton>
          <StyledButton
            onClick={() => setFilter("verified")}
            active={filter === "verified"}
            className="bg-gray-700 text-gray-100 hover:bg-gray-600"
          >
            Verified
          </StyledButton>
          <StyledButton
            onClick={() => setFilter("pending")}
            active={filter === "pending"}
            className="bg-gray-700 text-gray-100 hover:bg-gray-600"
          >
            Pending
          </StyledButton>
        </div>
      </div>
      <div className="max-h-[70vh] overflow-y-auto space-y-4">
        <AnimatePresence>
          {filteredSubmissions.map((submission: any) => (
            <StyledCard
              key={submission.id}
              layout
              {...animations.item}
              onClick={() => toggleTaskExpansion(submission.id)}
              className="bg-gray-800 rounded-lg shadow-md overflow-hidden cursor-pointer mb-4 hover:bg-gray-750"
            >
              <div className="p-6">
                <div className="flex items-center justify-between">
                  <h3 className="text-xl font-semibold text-gray-100">
                    {submission.task.task_name}
                  </h3>
                  <span
                    className={`px-3 py-1 rounded-full text-sm font-semibold ${
                      submission.verification_status
                        ? "bg-green-800 text-green-100"
                        : "bg-yellow-800 text-yellow-100"
                    }`}
                  >
                    {submission.verification_status ? "Verified" : "Pending"}
                  </span>
                </div>
                <div className="mt-4 flex flex-wrap gap-4">
                  <div className="flex items-center space-x-2">
                    <span className="text-sm font-medium text-gray-400">
                      Completed:
                    </span>
                    <span className="text-sm text-gray-300">
                      {new Date(submission.created_at).toLocaleDateString()}
                    </span>
                  </div>
                  {submission.verification_status && (
                    <div className="flex items-center space-x-2">
                      <span className="text-sm font-medium text-gray-400">
                        Points:
                      </span>
                      <span className="text-sm text-gray-300">
                        {submission.points_awarded}
                      </span>
                    </div>
                  )}
                </div>
                <AnimatePresence>
                  {expandedTask === submission.id && (
                    <motion.div
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: "auto" }}
                      exit={{ opacity: 0, height: 0 }}
                      transition={{ duration: 0.3 }}
                      className="mt-4"
                    >
                      <p className="text-gray-300">
                        {submission.task.task_desc}
                      </p>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </StyledCard>
          ))}
        </AnimatePresence>
      </div>
    </motion.div>
  );
};

export default CompletedTasks;
