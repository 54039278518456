import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  FC,
  ChangeEvent,
  FocusEvent,
} from "react";
import { useNavigate } from "react-router-dom";
import {
  Search,
  ChevronRight,
  X,
  Calendar,
  MapPin,
  DollarSign,
  Users,
  PlusCircle,
  Edit,
  Trash2,
  Sun,
  Moon,
  Download,
  Info,
  ArrowRight,
} from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import {
  useGetMilanEventQuery,
  useUpdateMilanEventMutation,
  useDeleteMilanEventMutation,
  useCreateMilanEventMutation,
  useGetMilanRegistrationsByEventIdQuery,
} from "../../../generated/graphql";
const ThemeContext = createContext({
  isDarkMode: false,
  toggleTheme: () => {},
});
interface Registration {
  event_id: string;
  id: string;
  // participants: number;
  razorpay_order_id: string;
  user_id: string;
  verified: boolean;
  link?:string|undefined|null;
  
}
interface User {
  user_id: string;
  username: string;
  verified?: boolean | null | undefined;
  year_of_study?: string | null;
  email_primary?: string | null;
  mobile_number_primary?: string | null;
}
interface RegistrationData {
  event: MilanEvent;
  registration: Registration;
  user: User;
}

interface MilanEvent {
  id: string;
  name: string;
  description: string[];
  date: string;
  price: number;
  city: string;
  venue: string;
  maxParticipants: number;
}
interface CitySearchDropdownProps {
  isDarkMode: boolean;
}

const RegistrationsPopup: React.FC<{
  eventId: string;
  eventName: string;
  onClose: () => void;
}> = ({ eventId, eventName, onClose }) => {
  const { isDarkMode } = useContext(ThemeContext);
  const { data, loading, error } = useGetMilanRegistrationsByEventIdQuery({
    variables: { eventId },
  });

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
    >
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        transition={{ type: "spring", damping: 25, stiffness: 500 }}
        className={`${
          isDarkMode ? "bg-gray-800" : "bg-white"
        } rounded-2xl max-w-5xl w-full max-h-[90vh] overflow-y-auto relative p-8`}
      >
        <button
          onClick={onClose}
          className={`absolute top-6 right-6 ${
            isDarkMode
              ? "text-gray-300 hover:text-gray-100"
              : "text-gray-500 hover:text-gray-700"
          } transition-colors duration-200`}
        >
          <X size={28} />
        </button>

        <h2
          className={`text-3xl font-bold mb-6 ${
            isDarkMode ? "text-white" : "text-gray-800"
          }`}
        >
          Registrations for {eventName}
        </h2>

        {loading && (
          <p className={`${isDarkMode ? "text-gray-300" : "text-gray-600"}`}>
            Loading registrations...
          </p>
        )}

        {error && (
          <p className="text-red-500">
            Error loading registrations: {error.message}
          </p>
        )}

        {data?.getMilanRegistrationsByEventId && (
          <div className="overflow-x-auto">
            <table className="w-full border-collapse">
              <thead>
                <tr
                  className={`${
                    isDarkMode ? "text-gray-300" : "text-gray-700"
                  }`}
                >
                  <th className="text-left p-3 border-b">Registration ID</th>
                  <th className="text-left p-3 border-b">Username</th>
                  <th className="text-left p-3 border-b">Year of Study</th>
                  {/* <th className="text-left p-3 border-b">Participants</th> */}
                  <th className="text-left p-3 border-b">Order ID</th>
                  <th className="text-center p-3 border-b">User Verified</th>
                  <th className="text-center p-3 border-b">Payment Status</th>
                </tr>
              </thead>
              <tbody>
                {data.getMilanRegistrationsByEventId.map(
                  (regData: RegistrationData) => (
                    <tr
                      key={regData.registration.id}
                      className={`${
                        isDarkMode
                          ? "text-gray-300 hover:bg-gray-700"
                          : "text-gray-600 hover:bg-gray-50"
                      }`}
                    >
                      <td className="p-3 border-b">
                        {regData.registration.id}
                      </td>
                      <td className="p-3 border-b">{regData.user.username}</td>
                      <td className="p-3 border-b">
                        {regData.user.year_of_study}
                      </td>
                      {/* <td className="p-3 border-b">
                        {regData.registration.participants}
                      </td> */}
                      <td className="p-3 border-b">
                        {regData.registration.razorpay_order_id}
                      </td>
                      <td className="p-3 border-b text-center">
                        <span
                          className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                            regData.user.verified
                              ? "bg-green-100 text-green-800"
                              : "bg-yellow-100 text-yellow-800"
                          }`}
                        >
                          {regData.user.verified ? "Verified" : "Pending"}
                        </span>
                      </td>
                      <td className="p-3 border-b text-center">
                        <span
                          className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                            regData.registration.verified
                              ? "bg-green-100 text-green-800"
                              : "bg-yellow-100 text-yellow-800"
                          }`}
                        >
                          {regData.registration.verified ? "Paid" : "Pending"}
                        </span>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>

            {data.getMilanRegistrationsByEventId.length === 0 && (
              <p
                className={`text-center py-4 ${
                  isDarkMode ? "text-gray-300" : "text-gray-600"
                }`}
              >
                No registrations found for this event.
              </p>
            )}
          </div>
        )}
      </motion.div>
    </motion.div>
  );
};

const EventRibbon: React.FC<{
  event: MilanEvent;
  onClick: (event: MilanEvent) => void;
  onEdit: (event: MilanEvent) => void;
  onDelete: (eventId: string) => void;
}> = ({ event, onClick, onEdit, onDelete }) => {
  const { isDarkMode } = useContext(ThemeContext);
  const [showDetails, setShowDetails] = useState(false);
  const [showRegistrations, setShowRegistrations] = useState(false);

  // Query to fetch registrations for the specific event
  const { data: registrationData } = useGetMilanRegistrationsByEventIdQuery({
    variables: { eventId: event.id },
  });

  const downloadRegistrationsCSV = (e: React.MouseEvent) => {
    e.stopPropagation();

    if (!registrationData?.getMilanRegistrationsByEventId) {
      return;
    }

    // Define CSV headers
    const headers = [
      "Event Name",
      "Event City",
      "Username",
      // "Participants",
      "Order ID",
      "email",
      "mobile number",
      "price",
      "drive link"
      // "amount",
    ];

    // Convert registrations to CSV rows
    const rows = registrationData.getMilanRegistrationsByEventId.map(
      (regData: RegistrationData) => [
        regData.event.name,
        regData.event.city,
        regData.user.username,
        // regData.registration.participants,
        regData.registration.razorpay_order_id,
        regData.user.email_primary,
        regData.user.mobile_number_primary,
        regData.event.price,
        regData.registration.link
        // regData.event.price * regData.registration.participants,
      ]
    );

    // Combine headers and rows
    const csvContent = [
      headers.join(","),
      ...rows.map((row) => row.join(",")),
    ].join("\n");

    // Create and trigger download
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      `${event.name}_registrations.csv`.replace(/\s+/g, "_")
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.3 }}
        className={`flex items-center p-6 mb-4 rounded-xl shadow-lg cursor-pointer transition-all duration-300 transform hover:-translate-y-1 hover:shadow-xl ${
          isDarkMode
            ? "bg-gray-800 hover:bg-gray-700 text-white"
            : "bg-white hover:bg-gray-50 text-gray-800"
        }`}
        onClick={() => onClick(event)}
      >
        <div className="flex-grow">
          <h3 className="text-xl font-semibold mb-1">{event.name}</h3>
          <p
            className={`text-sm ${
              isDarkMode ? "text-gray-300" : "text-gray-600"
            }`}
          >
            {event.city} - {new Date(event.date).toLocaleDateString()}
          </p>
        </div>
        <div className="flex items-center">
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className={`mr-2 p-2 rounded-full ${
              isDarkMode
                ? "text-purple-300 hover:bg-purple-900"
                : "text-purple-600 hover:bg-purple-100"
            }`}
            onClick={(e) => {
              e.stopPropagation();
              setShowDetails(true);
            }}
          >
            <Info size={20} />
          </motion.button>
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className={`mr-2 p-2 rounded-full ${
              isDarkMode
                ? "text-blue-300 hover:bg-blue-900"
                : "text-blue-600 hover:bg-blue-100"
            }`}
            onClick={(e) => {
              e.stopPropagation();
              setShowRegistrations(true);
            }}
          >
            <Users size={20} />
          </motion.button>
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className={`mr-2 p-2 rounded-full ${
              isDarkMode
                ? "text-green-300 hover:bg-green-900"
                : "text-green-600 hover:bg-green-100"
            }`}
            onClick={downloadRegistrationsCSV}
            title="Download Registrations CSV"
          >
            <Download size={20} />
          </motion.button>
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className={`mr-2 p-2 rounded-full ${
              isDarkMode
                ? "text-gray-300 hover:bg-gray-700"
                : "text-gray-600 hover:bg-gray-200"
            }`}
            onClick={(e) => {
              e.stopPropagation();
              onEdit(event);
            }}
          >
            <Edit size={20} />
          </motion.button>
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className={`mr-4 p-2 rounded-full ${
              isDarkMode
                ? "text-red-400 hover:bg-red-900"
                : "text-red-500 hover:bg-red-100"
            }`}
            onClick={(e) => {
              e.stopPropagation();
              onDelete(event.id);
            }}
          >
            <Trash2 size={20} />
          </motion.button>
          <ChevronRight
            className={`${isDarkMode ? "text-gray-400" : "text-gray-400"}`}
            size={24}
          />
        </div>
      </motion.div>
      <AnimatePresence>
        {showRegistrations && (
          <RegistrationsPopup
            eventId={event.id}
            eventName={event.name}
            onClose={() => setShowRegistrations(false)}
          />
        )}
      </AnimatePresence>
    </>
  );
};

const EventPopup: React.FC<{
  event: MilanEvent;
  onClose: () => void;
  onEdit: (event: MilanEvent) => void;
}> = ({ event, onClose, onEdit }) => {
  const { isDarkMode } = useContext(ThemeContext);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
    >
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        transition={{ type: "spring", damping: 25, stiffness: 500 }}
        className={`${
          isDarkMode ? "bg-gray-800" : "bg-white"
        } rounded-2xl max-w-2xl w-full max-h-[90vh] overflow-y-auto relative p-8`}
      >
        <button
          onClick={onClose}
          className={`absolute top-6 right-6 ${
            isDarkMode
              ? "text-gray-300 hover:text-gray-100"
              : "text-gray-500 hover:text-gray-700"
          } transition-colors duration-200`}
        >
          <X size={28} />
        </button>
        <h2
          className={`text-3xl font-bold mb-4 ${
            isDarkMode ? "text-white" : "text-gray-800"
          }`}
        >
          {event.name}
        </h2>
        <ul className="list-disc pl-6 mb-4">
          {event.description.map((desc, index) => (
            <li
              key={index}
              className={`text-lg mb-2 ${
                isDarkMode ? "text-gray-300" : "text-gray-600"
              }`}
            >
              {desc}
            </li>
          ))}
        </ul>
        <div
          className={`flex items-center mb-4 ${
            isDarkMode ? "text-gray-300" : "text-gray-600"
          }`}
        >
          <Calendar size={20} className="mr-2" />
          <span>Date: {new Date(event.date).toLocaleDateString()}</span>
        </div>
        <div
          className={`flex items-center mb-4 ${
            isDarkMode ? "text-gray-300" : "text-gray-600"
          }`}
        >
          <MapPin size={20} className="mr-2" />
          <span>
            Location: {event.city}, {event.venue}
          </span>
        </div>
        <div
          className={`flex items-center mb-4 ${
            isDarkMode ? "text-gray-300" : "text-gray-600"
          }`}
        >
          <DollarSign size={20} className="mr-2" />
          <span>Price: ${event.price}</span>
        </div>
        <div
          className={`flex items-center ${
            isDarkMode ? "text-gray-300" : "text-gray-600"
          }`}
        >
          <Users size={20} className="mr-2" />
          <span>Max Participants: {event.maxParticipants}</span>
        </div>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => onEdit(event)}
          className={`mt-6 px-4 py-2 rounded-lg ${
            isDarkMode
              ? "bg-blue-600 hover:bg-blue-700 text-white"
              : "bg-blue-500 hover:bg-blue-600 text-white"
          } transition-colors duration-200`}
        >
          Edit Event
        </motion.button>
      </motion.div>
    </motion.div>
  );
};
const EventForm: React.FC<{
  event: MilanEvent | null;
  onSubmit: (event: Omit<MilanEvent, "id">) => void;
  onClose: () => void;
}> = ({ event, onSubmit, onClose }) => {
  const { isDarkMode } = useContext(ThemeContext);
  const [name, setName] = useState(event?.name || "");
  const [description, setDescription] = useState(event?.description || [""]);
  const [date, setDate] = useState(
    event?.date ? new Date(event.date).toISOString().split("T")[0] : ""
  );
  const [price, setPrice] = useState(event?.price || 0);
  const [city, setCity] = useState(event?.city || "");
  const [venue, setVenue] = useState(event?.venue || "");
  const [maxParticipants, setMaxParticipants] = useState(
    event?.maxParticipants || 0
  );

  const handleDescriptionChange = (index: number, value: string) => {
    const newDescription = [...description];
    newDescription[index] = value;
    setDescription(newDescription);
  };

  const addDescriptionField = () => {
    setDescription([...description, ""]);
  };

  const removeDescriptionField = (index: number) => {
    const newDescription = description.filter((_, i) => i !== index);
    setDescription(newDescription);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit({
      name,
      description: description.filter((desc) => desc.trim() !== ""),
      date: new Date(date).toISOString(),
      price,
      city,
      venue,
      maxParticipants,
    });
  };
  const cities = ["trichy", "tirupati", "online", "bangalore", "kolkata", "pune"];

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
    >
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        transition={{ type: "spring", damping: 25, stiffness: 500 }}
        className={`${
          isDarkMode ? "bg-gray-800" : "bg-white"
        } rounded-2xl max-w-2xl w-full max-h-[90vh] overflow-y-auto relative p-8`}
      >
        <button
          onClick={onClose}
          className={`absolute top-6 right-6 ${
            isDarkMode
              ? "text-gray-300 hover:text-gray-100"
              : "text-gray-500 hover:text-gray-700"
          } transition-colors duration-200`}
        >
          <X size={28} />
        </button>
        <h2
          className={`text-3xl font-bold mb-6 ${
            isDarkMode ? "text-white" : "text-gray-800"
          }`}
        >
          {event ? "Edit Event" : "Add New Event"}
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="name"
              className={`block mb-2 ${
                isDarkMode ? "text-gray-300" : "text-gray-700"
              }`}
            >
              Event Name
            </label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className={`w-full p-2 rounded-lg ${
                isDarkMode
                  ? "bg-gray-700 text-white"
                  : "bg-gray-100 text-gray-800"
              } focus:outline-none focus:ring-2 focus:ring-blue-500`}
              required
            />
          </div>
          <div className="mb-4">
            <label
              className={`block mb-2 ${
                isDarkMode ? "text-gray-300" : "text-gray-700"
              }`}
            >
              Description (bullet points)
            </label>
            {description.map((desc, index) => (
              <div key={index} className="flex mb-2">
                <input
                  type="text"
                  value={desc}
                  onChange={(e) =>
                    handleDescriptionChange(index, e.target.value)
                  }
                  className={`flex-grow p-2 rounded-lg ${
                    isDarkMode
                      ? "bg-gray-700 text-white"
                      : "bg-gray-100 text-gray-800"
                  } focus:outline-none focus:ring-2 focus:ring-blue-500`}
                  placeholder="Enter a bullet point"
                />
                <button
                  type="button"
                  onClick={() => removeDescriptionField(index)}
                  className={`ml-2 px-2 py-1 rounded ${
                    isDarkMode
                      ? "bg-red-600 text-white"
                      : "bg-red-500 text-white"
                  }`}
                >
                  Remove
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={addDescriptionField}
              className={`mt-2 px-4 py-2 rounded ${
                isDarkMode
                  ? "bg-green-600 text-white"
                  : "bg-green-500 text-white"
              }`}
            >
              Add Bullet Point
            </button>
          </div>
          <div className="mb-4">
            <label
              htmlFor="date"
              className={`block mb-2 ${
                isDarkMode ? "text-gray-300" : "text-gray-700"
              }`}
            >
              Event Date
            </label>
            <input
              type="date"
              id="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              className={`w-full p-2 rounded-lg ${
                isDarkMode
                  ? "bg-gray-700 text-white"
                  : "bg-gray-100 text-gray-800"
              } focus:outline-none focus:ring-2 focus:ring-blue-500`}
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="price"
              className={`block mb-2 ${
                isDarkMode ? "text-gray-300" : "text-gray-700"
              }`}
            >
              Price
            </label>
            <input
              type="number"
              id="price"
              value={price}
              onChange={(e) => setPrice(Number(e.target.value))}
              className={`w-full p-2 rounded-lg ${
                isDarkMode
                  ? "bg-gray-700 text-white"
                  : "bg-gray-100 text-gray-800"
              } focus:outline-none focus:ring-2 focus:ring-blue-500`}
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="city"
              className={`block mb-2 ${
                isDarkMode ? "text-gray-300" : "text-gray-700"
              }`}
            >
              City
            </label>
            <select
              id="city"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              className={`w-full p-2 rounded-lg ${
                isDarkMode
                  ? "bg-gray-700 text-white"
                  : "bg-gray-100 text-gray-800"
              } focus:outline-none focus:ring-2 focus:ring-blue-500`}
              required
            >
              <option value="" disabled>
                Select a city
              </option>
              {cities.map((cityOption) => (
                <option key={cityOption} value={cityOption}>
                  {cityOption}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label
              htmlFor="venue"
              className={`block mb-2 ${
                isDarkMode ? "text-gray-300" : "text-gray-700"
              }`}
            >
              Venue
            </label>
            <input
              type="text"
              id="venue"
              value={venue}
              onChange={(e) => setVenue(e.target.value)}
              className={`w-full p-2 rounded-lg ${
                isDarkMode
                  ? "bg-gray-700 text-white"
                  : "bg-gray-100 text-gray-800"
              } focus:outline-none focus:ring-2 focus:ring-blue-500`}
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="maxParticipants"
              className={`block mb-2 ${
                isDarkMode ? "text-gray-300" : "text-gray-700"
              }`}
            >
              Max Participants
            </label>
            <input
              type="number"
              id="maxParticipants"
              value={maxParticipants}
              onChange={(e) => setMaxParticipants(Number(e.target.value))}
              className={`w-full p-2 rounded-lg ${
                isDarkMode
                  ? "bg-gray-700 text-white"
                  : "bg-gray-100 text-gray-800"
              } focus:outline-none focus:ring-2 focus:ring-blue-500`}
              required
            />
          </div>
          <div className="flex justify-end">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              type="submit"
              className={`px-4 py-2 rounded-lg ${
                isDarkMode
                  ? "bg-blue-600 hover:bg-blue-700 text-white"
                  : "bg-blue-500 hover:bg-blue-600 text-white"
              } transition-colors duration-200`}
            >
              {event ? "Update Event" : "Add Event"}
            </motion.button>
          </div>
        </form>
      </motion.div>
    </motion.div>
  );
};

const MilanEventManager: React.FC = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedEvent, setSelectedEvent] = useState<MilanEvent | null>(null);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [editingEvent, setEditingEvent] = useState<MilanEvent | null>(null);
  const navigate = useNavigate();
  const toggleTheme = () => setIsDarkMode(!isDarkMode);

  //const [searchTerm, setSearchTerm] = useState<string>('');
  const [cities, setCities] = useState<string[]>([]);
  const [filteredCities, setFilteredCities] = useState<string[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const [updateMilanEvent] = useUpdateMilanEventMutation();
  const [deleteMilanEvent] = useDeleteMilanEventMutation();
  const [createMilanEvent] = useCreateMilanEventMutation();
  const { data, loading, error, refetch } = useGetMilanEventQuery();
  const [filteredEvents, setFilteredEvents] = useState<MilanEvent[]>([]);

  useEffect(() => {
    if (data && data.getMilanEvent) {
      const filtered = data.getMilanEvent.filter((event: MilanEvent) =>
        event.city.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredEvents(filtered);
    }
  }, [data, searchTerm]);
  useEffect(() => {
    refetch();
  }, [searchTerm, refetch]);

  const handleEventClick = (event: MilanEvent) => {
    setSelectedEvent(event);
  };

  const handleClosePopup = () => {
    setSelectedEvent(null);
  };

  const handleAddEvent = () => {
    setEditingEvent(null);
    setIsFormOpen(true);
  };

  const handleEditEvent = (event: MilanEvent) => {
    setEditingEvent(event);
    setIsFormOpen(true);
  };

  const handleDeleteEvent = async (eventId: string) => {
    try {
      await deleteMilanEvent({ variables: { deleteMilanEventId: eventId } });
      refetch();
    } catch (error) {
      console.error("Error deleting event:", error);
    }
  };

  const handleFormSubmit = async (eventData: Omit<MilanEvent, "id">) => {
    try {
      if (editingEvent) {
        await updateMilanEvent({
          variables: {
            ...eventData,
            updateMilanEventId: editingEvent.id,
          },
        });
      } else {
        await createMilanEvent({
          variables: {
            ...eventData,
            date: new Date(eventData.date).toISOString(), // Ensure date is in ISO format
            price: parseFloat(eventData.price.toString()), // Ensure price is a float
            maxParticipants: parseFloat(eventData.maxParticipants.toString()), // Ensure maxParticipants is a float
          },
        });
      }
      setIsFormOpen(false);
      refetch();
    } catch (error) {
      console.error("Error saving event:", error);
    }
  };

  //search related :

  useEffect(() => {
    setCities(["trichy", "tirupati", "online", "bangalore", "kolkata", "pune"]);  
  }, []);

  useEffect(() => {
    if (searchTerm === "") {
      setFilteredCities(cities);
    } else {
      const filtered = cities.filter((city) =>
        city.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredCities(filtered);
    }
  }, [searchTerm, cities]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleInputFocus = () => {
    setIsDropdownOpen(true);
  };

  const handleInputBlur = () => {
    // Delay closing the dropdown to allow for city selection
    setTimeout(() => setIsDropdownOpen(false), 200);
  };

  const handleCitySelect = (city: string) => {
    setSearchTerm(city);
    setIsDropdownOpen(false);
  };

  //search related over

  //csv realted
  const generateCSV = (events: MilanEvent[]) => {
    const header = [
      "Name",
      "Description",
      "Date",
      "Price",
      "City",
      "Venue",
      "Max Participants",
    ];
    const rows = events.map((event) => [
      event.name,
      event.description.join("; "),
      new Date(event.date).toLocaleDateString(),
      event.price.toString(),
      event.city,
      event.venue,
      event.maxParticipants.toString(),
    ]);

    const csvContent = [
      header.join(","),
      ...rows.map((row) => row.join(",")),
    ].join("\n");

    return csvContent;
  };

  const downloadCSV = () => {
    const csvContent = generateCSV(filteredEvents);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "milan_events.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  //close csv related

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleTheme }}>
      <div
        className={`min-h-screen ${isDarkMode ? "bg-gray-900" : "bg-gray-100"}`}
      >
        <nav
          className={`p-4 ${isDarkMode ? "bg-gray-800" : "bg-white"} shadow-md`}
        >
          <div className="container mx-auto flex justify-between items-center">
            <h1
              className={`text-2xl font-bold ${
                isDarkMode ? "text-white" : "text-gray-800"
              }`}
            >
              Milans Event Manager
            </h1>
            <div className="flex items-center">
              <motion.button
                onClick={() => navigate("/admin")}
                className={`flex items-center justify-center px-4 py-2 rounded-lg font-semibold text-white transition-colors duration-300 ${
                  isDarkMode
                    ? "bg-blue-600 hover:bg-blue-700"
                    : "bg-blue-500 hover:bg-blue-600"
                }`}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
              >
                <span className="mr-2">Return to Admin</span>
                <motion.div
                  animate={{ x: [0, 5, 0] }}
                  transition={{ repeat: Infinity, duration: 1.5 }}
                >
                  <ArrowRight size={20} />
                </motion.div>
              </motion.button>
              <button
                onClick={toggleTheme}
                className={`p-2 rounded-full ${
                  isDarkMode
                    ? "bg-gray-700 text-yellow-400"
                    : "bg-gray-200 text-gray-800"
                }`}
              >
                {isDarkMode ? <Sun size={24} /> : <Moon size={24} />}
              </button>
            </div>
          </div>
        </nav>
        <div className="container mx-auto p-4">
          <div className="mb-6 flex items-center">
            <div className="relative flex-grow">
              <input
                type="text"
                placeholder="Search events by city..."
                value={searchTerm}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                className={`w-full p-3 pr-10 rounded-lg ${
                  isDarkMode
                    ? "bg-gray-700 text-white placeholder-gray-400"
                    : "bg-white text-gray-800 placeholder-gray-400"
                } focus:outline-none focus:ring-2 focus:ring-blue-500`}
              />
              <Search
                className={`absolute right-3 top-1/2 transform -translate-y-1/2 ${
                  isDarkMode ? "text-gray-400" : "text-gray-400"
                }`}
                size={20}
              />
              {isDropdownOpen && filteredCities.length > 0 && (
                <ul
                  className={`absolute z-10 w-full mt-1 rounded-lg shadow-lg max-h-60 overflow-y-auto ${
                    isDarkMode
                      ? "bg-gray-700 text-white"
                      : "bg-white text-gray-800"
                  }`}
                >
                  {filteredCities.map((city, index) => (
                    <li
                      key={index}
                      className={`px-4 py-2 cursor-pointer hover:bg-opacity-10 ${
                        isDarkMode ? "hover:bg-gray-600" : "hover:bg-gray-100"
                      }`}
                      onClick={() => handleCitySelect(city)}
                    >
                      {city}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleAddEvent}
              className={`ml-4 px-4 py-2 rounded-lg flex items-center ${
                isDarkMode
                  ? "bg-blue-600 hover:bg-blue-700 text-white"
                  : "bg-blue-500 hover:bg-blue-600 text-white"
              } transition-colors duration-200`}
            >
              <PlusCircle size={20} className="mr-2" />
              Add Event
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={downloadCSV}
              className={`ml-4 px-4 py-2 rounded-lg flex items-center ${
                isDarkMode
                  ? "bg-green-600 hover:bg-green-700 text-white"
                  : "bg-green-500 hover:bg-green-600 text-white"
              } transition-colors duration-200`}
            >
              <Download size={20} className="mr-2" />
              Download CSV
            </motion.button>
          </div>
          {loading && (
            <p
              className={`text-center ${
                isDarkMode ? "text-white" : "text-gray-800"
              }`}
            >
              Loading...
            </p>
          )}
          {error && (
            <p className="text-center text-red-500">Error: {error.message}</p>
          )}
          {filteredEvents.length > 0 ? (
            <AnimatePresence>
              {filteredEvents.map((event: MilanEvent) => (
                <EventRibbon
                  key={event.id}
                  event={event}
                  onClick={handleEventClick}
                  onEdit={handleEditEvent}
                  onDelete={handleDeleteEvent}
                />
              ))}
            </AnimatePresence>
          ) : (
            <p
              className={`text-center ${
                isDarkMode ? "text-white" : "text-gray-800"
              }`}
            >
              No events found for the given search term.
            </p>
          )}
        </div>
        <AnimatePresence>
          {selectedEvent && (
            <EventPopup
              event={selectedEvent}
              onClose={handleClosePopup}
              onEdit={handleEditEvent}
            />
          )}
        </AnimatePresence>
        <AnimatePresence>
          {isFormOpen && (
            <EventForm
              event={editingEvent}
              onSubmit={handleFormSubmit}
              onClose={() => setIsFormOpen(false)}
            />
          )}
        </AnimatePresence>
      </div>
    </ThemeContext.Provider>
  );
};

export default MilanEventManager;
