import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import SidebarA from "../../../combonents/sa/sidebar/sidebar";

const Dashboard = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setSidebarOpen(window.innerWidth > 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <Box className="w-full h-full flex relative">
      <SidebarA
        isOpen={isSidebarOpen}
        onToggle={toggleSidebar}
        isMobile={isMobile}
      />
      <Box
        className="w-full h-[100vh] bg-slate-900 overflow-y-auto transition-all duration-300 ease-in-out"
        sx={{
          ml: {
            xs: 0,
            md: isSidebarOpen ? "20rem" : "5rem",
          },
          width: {
            xs: "100%",
            md: isSidebarOpen ? "calc(100% - 20rem)" : "calc(100% - 5rem)",
          },
        }}
      >
        <Box className="ml-12">
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
